/**
 * A component to tasks. If there is more than one task a carousel is displayed.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import { i18n } from '@geomagic/i18n';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';

import Placeholder from '@components/Placeholder';
import Carousel from '@geomagic/nam-react-core/components/Carousel';

import { getEntityType } from '@geomagic/geonam';

import Task from './Task';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(0, 1),
  },
}));

const getTasks = processInstances => {
  return flatten(
    processInstances.map(({ name, tasks }) => tasks.map(task => ({ ...task, processInstanceName: name })))
  );
};

const Tasks = props => {
  const {
    checkFailedMessage,
    closeableErrorText,
    data,
    entityClasses,
    isCloseable,
    isMobile,
    isOnline,
    onCheck,
    onSyncEntity,
    onUpdateEntity,
    user,
  } = props;

  const classes = useStyles(props);
  const [isLoading, setLoading] = useState(false);

  const entity = data?.getPatchedEntity();
  const { processInstances = [] } = entity;

  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const entityName = entity?.displayName || entityType?.name;

  const tasks = getTasks(processInstances);

  return (
    <div className={classes.root}>
      {tasks.length === 0 ? (
        <Placeholder
          icon={<AssignmentIcon />}
          title={i18n.t('process.placeholder.noTasks.title')}
          subtitle={i18n.t('process.placeholder.noTasks.content', {
            variables: {
              entityName,
            },
          })}
        />
      ) : (
        <Carousel className={classes.content}>
          {tasks.map(task => {
            return (
              <Task
                key={task?.id}
                checkFailedMessage={checkFailedMessage}
                closeableErrorText={closeableErrorText}
                data={data}
                entity={entity}
                entityClasses={entityClasses}
                isCloseable={isCloseable}
                isOnline={isOnline}
                isLoading={isLoading}
                isMobile={isMobile}
                task={task}
                onCheck={onCheck}
                onSyncEntity={onSyncEntity}
                onUpdateEntity={onUpdateEntity}
                setLoading={setLoading}
                user={user}
              />
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

Tasks.propTypes = {
  checkFailedMessage: PropTypes.string,
  closeableErrorText: PropTypes.string,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  isCloseable: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  onCheck: PropTypes.func,
  onSyncEntity: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default Tasks;
