import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { FORM_COMPONENT_MIN_HEIGHT, FORM_COMPONENT_TOGGLE_BUTTON_WIDTH } from '../consts';
import getKey from '../utils/getKey';

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing }) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    margin: spacing(1, 0.5),
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.down('md')]: {
      marginBottom: spacing(1),
    },
  },
  buttonGroup: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  button: {
    flex: 1,
    minHeight: FORM_COMPONENT_MIN_HEIGHT,
    minWidth: FORM_COMPONENT_TOGGLE_BUTTON_WIDTH,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&$selected': {
      backgroundColor: `${palette.primary.light} !important`,
      color: `${palette.getContrastText(palette.primary.main)} !important`,
    },
    '& span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  isMandatory: {
    border: `2px solid ${palette.warning.main}`,
    borderRadius: 8,
  },
  isMandatoryWithValue: {
    border: `2px solid transparent`,
    borderRadius: 8,
  },
  isValid: {
    border: `2px solid ${palette.success.main}`,
  },
  readOnly: {
    pointerEvents: 'none',
  },
  selected: {},
}));

const EnumToggle = props => {
  const { context, data, path } = props;
  const { isReadOnly, onUpdate } = context;
  const { mandatory, statusValue, statusValues } = data;

  const [selectedStatusValue, setSelectedStatusValue] = useState(statusValue);
  const classes = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleChange = (event, value) => {
    const newStatusValue = statusValues.find(({ name: statusValueName }) => statusValueName === value) || null;

    if (statusValue !== newStatusValue) {
      setSelectedStatusValue(newStatusValue);
      onUpdate({ statusValue: newStatusValue }, path, data);
    }
  };

  return (
    <div className={classNames(classes.root, { [classes.readOnly]: isReadOnly })}>
      <ToggleButtonGroup
        className={classNames(classes.buttonGroup, {
          [classes.isMandatory]: mandatory && !statusValue,
          [classes.isMandatoryWithValue]: mandatory && statusValue,
        })}
        exclusive
        onChange={handleChange}
        value={selectedStatusValue && selectedStatusValue.name}
      >
        {statusValues.map(({ name: statusValueName }, index) => (
          <ToggleButton
            key={getKey(statusValueName, index)}
            className={classes.button}
            classes={{ selected: classes.selected }}
            value={statusValueName}
          >
            {statusValueName}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

EnumToggle.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default EnumToggle;
