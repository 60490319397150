/**
 * Flatten deep by key
 * @param {array} array
 * @param {string} key
 * @returns {array}
 */

const flattenDeepByKey = (array, key) => {
  return array.reduce((prev, curr) => {
    prev.push(curr);
    curr[key] && prev.push(...flattenDeepByKey(curr[key], key));
    return prev;
  }, []);
};

export default flattenDeepByKey;
