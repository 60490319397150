import uuidv4 from 'uuid/v4';
import isNumber from 'lodash/isNumber';
import getExtendedEntity from './getExtendedEntity';
import { REMOTE_VERSION } from './consts';

/**
 * Get a database doc
 * @param {Object} params
 * @param {Object} params.entity - GeoNAM entity
 * @param {Object|null} params.draft
 * @param {Object|null} params.relations
 * @param {Object} params.mapProps - GeoNAM mapProps (world, view, srid)
 * @returns {Object|void}
 */

const getDocTemplate = ({ additionalProperties = {}, entity, isDraft = false, mapProps, relations = null, userId }) => {
  if (entity) {
    const extendedEntity = getExtendedEntity(entity, mapProps);

    return {
      uuid: isNumber(extendedEntity?.id) ? uuidv4() : extendedEntity?.id,
      entity: extendedEntity,
      createdOn: +new Date(),
      modifiedOn: +new Date(),
      jsonPatch: null,
      offlineMap: null,
      draft: isDraft ? { closed: false } : null,
      relations,
      remoteVersion: REMOTE_VERSION,
      userId,
      ...additionalProperties,
    };
  } else {
    console.error('ENTITY IS REQUIRED');
  }
};

export default getDocTemplate;
