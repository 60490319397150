import { DEFAULT_FIT_OPTIONS } from '../consts';

/**
 * Center view by coordinates
 * @param {Object} params
 * @param {Object} params.view - OpenLayers view
 * @param {Array.<number>} params.extent - Extent to fit
 * @param {Object} params.options - Fit options without callback (https://openlayers.org/en/latest/apidoc/module-ol_View.html#~FitOptions)
 * @param {function} params.callback - Function which is called after fit
 * @returns {void}
 */

const fitView = ({ view, extent, options, callback }) => {
  view.fit(extent, {
    ...DEFAULT_FIT_OPTIONS,
    ...options,
    callback: successful => {
      if (!successful) {
        view.fit(extent, { ...options, duration: 0 });
      }
      callback && callback();
    },
  });
};

export default fitView;
