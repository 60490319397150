import isFunction from 'lodash/isFunction';
import { omitDeep } from '@patomation/omit-deep';

import getDocTemplate from './getDocTemplate';
import handleFetchError from './handleFetchError';
import updateDoc from './updateDoc';
import { ENTITY_SELECTOR_KEY, REMOTE_VERSION } from './consts';

import { CLASSNAME_DISPATCH } from '@graphql/consts';
import getEntityFilter from '@graphql/getEntityFilter';
import QueryDispatches from '@graphql/queries/QueryDispatches';
import getDefaultModifiedOn from '@database/getDefaultModifiedOn';

const pullNearbyDispatches = async ({ database, client, mapProps, userId, spatialFilter }) => {
  let state = {};
  const collectionName = 'dispatches';
  const getModifiedOn = getDefaultModifiedOn;

  const queryConfig = {
    query: QueryDispatches,
    variables: {
      filter: {
        ...getEntityFilter([CLASSNAME_DISPATCH]),
        ...spatialFilter,
        sort: {
          ascending: false,
          property: 'PROCESSING_STATUS',
        },
      },
      pagination: {
        limit: 20,
        offset: 0,
      },
    },
    fetchPolicy: 'no-cache',
  };

  try {
    const collection = database[collectionName];
    const { data } = await client.query(queryConfig);
    const remoteEntities = data?.entities?.values;

    if (remoteEntities) {
      let added = [];
      let conflicts = [];
      let removed = [];
      let updated = [];

      const removeSelector = {
        [ENTITY_SELECTOR_KEY]: { $nin: remoteEntities.map(({ id }) => id) },
        isNearby: true,
        isWorkbench: false,
        userId,
      };

      const docsRemoved = await collection.find({ selector: removeSelector }).remove();

      if (docsRemoved) {
        removed = docsRemoved;
      }

      for (let i = 0; i < remoteEntities.length; i++) {
        let entity = omitDeep(remoteEntities[i], '__typename');
        const selector = { [ENTITY_SELECTOR_KEY]: entity.id, userId };
        const existingDoc = await collection.findOne({ selector }).exec();
        const modifiedOn = isFunction(getModifiedOn) ? await getModifiedOn(entity) : null;
        const entityRemoteVersion = existingDoc?.remoteVersion;

        if (!existingDoc) {
          const doc = getDocTemplate({ additionalProperties: { isNearby: true }, entity, mapProps, userId });
          const insertedDoc = await collection.insert({
            ...doc,
            ...(modifiedOn && { modifiedOn }),
            remoteVersion: REMOTE_VERSION,
          });

          added.push(insertedDoc);
        } else {
          if (modifiedOn > existingDoc.modifiedOn || REMOTE_VERSION > entityRemoteVersion) {
            if (existingDoc?.jsonPatch) {
              conflicts.push({
                doc: existingDoc,
                entity,
                modifiedOn,
                remoteVersion: REMOTE_VERSION,
                userId,
              });
            } else {
              const updatedDoc = await updateDoc({
                additionalProperties: {
                  isNearby: true,
                },
                doc: existingDoc,
                entity,
                mapProps,
                modifiedOn,
                remoteVersion: REMOTE_VERSION,
                userId,
              });
              updated.push(updatedDoc);
            }
          }
        }
      }

      const collectionState = { added, conflicts, removed, updated };
      state[collectionName] = collectionState;
    }
  } catch (error) {
    console.log(error);
    handleFetchError({ error });
  }

  return state;
};

export default pullNearbyDispatches;
