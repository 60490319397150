import React from 'react';
import PropTypes from 'prop-types';

import AddPictureIcon from '@material-ui/icons/AddAPhoto';
import PictureIcon from '@material-ui/icons/ImageOutlined';
import { i18n } from '@geomagic/i18n';
import FormElementDocuments from './FormElementDocuments';

const FormElementPictures = props => {
  const accept = 'image/*';
  const documentLabel = i18n.t('assignment.button.pictures');
  const PlaceholderIconComponent = PictureIcon;
  const UploadIconComponent = AddPictureIcon;

  return (
    <FormElementDocuments
      {...props}
      accept={accept}
      documentLabel={documentLabel}
      PlaceholderIconComponent={PlaceholderIconComponent}
      placeholderSubtitle={i18n.t('placeholder.uploadImage.subtitle')}
      placeholderTitle={i18n.t('placeholder.uploadImage.title')}
      UploadIconComponent={UploadIconComponent}
    />
  );
};

FormElementPictures.propTypes = {
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default FormElementPictures;
