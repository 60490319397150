import getLayer from './getLayer';
/**
 * Set the layer visibility.
 * @param {*} map
 * @param {*} id
 * @param {*} vis
 */
const setLayerVisible = (map, id, vis) => {
  const layer = getLayer(id, map);
  if (layer) {
    layer.setVisible(vis);
  }
};

export default setLayerVisible;
