import moment from 'moment';
import { DATE_FORMAT, DATETIME_FORMAT } from '@consts';

/**
 * Get a raw value by specific GeoNAM attribute type
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {string} value
 * @returns {*}
 */

const getRawAttributeValue = (attributeType, value) => {
  switch (attributeType.dataType) {
    case 'STRING':
    case 'LINK':
    case 'EMAIL':
      return value;
    case 'INT':
    case 'FLOAT':
    case 'FRACTION':
    case 'CURRENCY':
      return String(value) || undefined;
    case 'BOOLEAN':
      if (value === true) {
        return 'true';
      } else if (value === false) {
        return 'false';
      }

      return;
    case 'DATE':
      return value ? moment(value).format(DATE_FORMAT) : undefined;
    case 'TIMESTAMP':
      return value ? moment(value).format(DATETIME_FORMAT) : undefined;
    default:
      break;
  }
};

export default getRawAttributeValue;
