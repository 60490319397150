import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { i18n } from '@geomagic/i18n';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { ListItemSecondaryAction } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(({ palette, spacing }) => {
  const spacingWidth = 3;

  return {
    root: {
      width: '100%',
    },
    listItem: ({ level }) => ({
      paddingLeft: '0px',
      marginLeft: level * spacing(spacingWidth),
      width: `calc(100% - ${level * spacing(spacingWidth)}px)`,
      border: '0px',
    }),
  };
});

const LayerListItem = props => {
  const { layer, map, isState, onHandleLayerClick } = props;

  const layerListItemtextElementRef = useRef();
  const [isOverflowed, setIsOverflow] = useState(false);
  const classes = useStyles(props);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setIsOverflow(layerListItemtextElementRef.current.scrollWidth > layerListItemtextElementRef.current.clientWidth);
  }, []);

  return (
    <ListItem
      disableGutters
      divider
      button
      className={classes.listItem}
      onClick={evt => {
        onHandleLayerClick(layer); //an dieser Stelle, damit man nicht unbedingt die Checkbox klicken muss
      }}
    >
      <ListItemText
        primary={
          <Tooltip title={layer.get('layerName')} disableHoverListener={!isOverflowed} placement="top-end" interactive>
            <Typography variant="body1" noWrap ref={layerListItemtextElementRef}>
              {layer.get('layerName')}
            </Typography>
          </Tooltip>
        }
        secondary={
          <>
            <Typography variant="inherit" color="error" noWrap>
              {layer.get('error')}
            </Typography>
            <Typography variant="inherit" color="textSecondary" noWrap>
              {layer.getSource() && layer.getSource().getState() !== 'ready'
                ? i18n.t('map.label.errorTreeLayerLoading')
                : ''}
              {layer.getMinResolution() && map.getView().getResolution() < layer.getMinResolution()
                ? i18n.t('map.label.errorZoomLevelBoundsZoomOut')
                : ''}
              {layer.getMaxResolution() && map.getView().getResolution() > layer.getMaxResolution()
                ? i18n.t('map.label.errorZoomLevelBoundsZoomIn')
                : ''}
            </Typography>
          </>
        }
      />
      <ListItemSecondaryAction>
        <Checkbox
          style={{ padding: '0px' }}
          color="primary"
          checked={isState === 1}
          onClick={evt => {
            onHandleLayerClick(layer);
          }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

LayerListItem.propTypes = {
  isState: PropTypes.number.isRequired,
  layer: PropTypes.object.isRequired,
  // ::: lv needed
  // eslint-disable-next-line react/no-unused-prop-types
  level: PropTypes.number.isRequired,
  map: PropTypes.object.isRequired,
  onHandleLayerClick: PropTypes.func.isRequired,
};

export default LayerListItem;
