import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';
import { omitDeep } from '@patomation/omit-deep';

import getDocTemplate from './getDocTemplate';
import handleFetchError from './handleFetchError';
import updateDoc from './updateDoc';
import { ENTITY_SELECTOR_KEY, ENTITY_UNIQ_KEY, REMOTE_VERSION } from './consts';

const getAdditionalCollectionEntitiesByConfig = (config, initialValue = []) => {
  let additionalCollectionEntities = {};

  config.forEach(({ collectionName }) => {
    additionalCollectionEntities[collectionName] = [];
  });

  return additionalCollectionEntities;
};

const updateAdditionalEntities = additionalEntities => (collectionName, values) => {
  if (additionalEntities[collectionName] && values) {
    const newValues = [...additionalEntities[collectionName], ...values];
    additionalEntities[collectionName] = uniqBy(newValues, ENTITY_UNIQ_KEY);
  }
};

const pull = async ({ database, client, config, mapProps, userId }) => {
  let state = {};
  let additionalCollectionEntities = getAdditionalCollectionEntitiesByConfig(config);

  for (let i = 0; i < config.length; i++) {
    const currentConfig = config[i];
    const { collectionName, getModifiedOn, after, beforeInsertDoc, transform } = currentConfig;

    try {
      let added = [];
      let conflicts = [];
      let removed = [];
      let updated = [];

      const collection = database[collectionName];
      const { data } = await client.query({ ...currentConfig?.query, fetchPolicy: 'no-cache' });
      const remoteEntities = data?.entities?.values;

      if (remoteEntities) {
        const additionalEntities = additionalCollectionEntities[collectionName];
        const entitiesToInsert = uniqBy([...remoteEntities, ...additionalEntities], ENTITY_UNIQ_KEY);

        //console.log(`DATA SYNCRONIZATION: ${collectionName} | ${remoteEntities.length} item(s) fetched`);
        //console.log(`DATA SYNCRONIZATION: ${collectionName} | ${additionalEntities.length} additional item(s)`);

        const removeSelector = {
          [ENTITY_SELECTOR_KEY]: { $nin: entitiesToInsert.map(({ id }) => id) },
          draft: null,
          userId,
        };

        const docsRemoved = await collection.find({ selector: removeSelector }).remove();

        if (docsRemoved) {
          removed = docsRemoved;
        }

        for (let i = 0; i < entitiesToInsert.length; i++) {
          let entity = omitDeep(entitiesToInsert[i], '__typename');
          const selector = { [ENTITY_SELECTOR_KEY]: entity.id, userId };
          const existingDoc = await collection.findOne({ selector }).exec();
          const modifiedOn = isFunction(getModifiedOn) ? await getModifiedOn(entity) : null;
          const entityRemoteVersion = existingDoc?.remoteVersion;

          if (beforeInsertDoc) {
            await beforeInsertDoc(entity, updateAdditionalEntities(additionalCollectionEntities));
          }

          if (transform) {
            entity = await transform(database, entity);
          }

          if (!existingDoc) {
            const doc = getDocTemplate({ entity, mapProps, userId });
            const insertedDoc = await collection.insert({
              ...doc,
              ...(modifiedOn && { modifiedOn }),
              remoteVersion: REMOTE_VERSION,
            });

            added.push(insertedDoc);
          } else {
            if (modifiedOn > existingDoc.modifiedOn || REMOTE_VERSION > entityRemoteVersion) {
              if (existingDoc?.jsonPatch) {
                conflicts.push({
                  doc: existingDoc,
                  entity,
                  modifiedOn,
                  remoteVersion: REMOTE_VERSION,
                  userId,
                });
              } else {
                const updatedDoc = await updateDoc({
                  doc: existingDoc,
                  entity,
                  mapProps,
                  modifiedOn,
                  remoteVersion: REMOTE_VERSION,
                  userId,
                });
                updated.push(updatedDoc);
              }
            }
          }
        }

        const collectionState = { added, conflicts, removed, updated };
        after && (await after(collectionState));
        state[collectionName] = collectionState;
      }
    } catch (error) {
      handleFetchError({ error });
    }
  }

  //console.log('DATA SYNCRONIZATION: finished | ', state);
  return state;
};

export default pull;
