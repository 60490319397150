import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import ListItem from '@components/ListItem';

import ListSkeleton from './ListSkeleton';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
  const isLightTheme = palette.type === 'light';

  return {
    root: {
      backgroundColor: palette.background.paper,
      flex: 1,
      overflow: 'auto',
      padding: 0,
      position: 'relative',
    },
    listSubheader: {
      backgroundColor: isLightTheme ? palette.grey[100] : palette.grey.A400,
      borderBottom: `1px solid ${palette.divider}`,
      zIndex: 2,
    },
    li: {
      listStyle: 'none',
    },
    ul: {
      margin: 0,
      padding: 0,
      backgroundColor: palette.background.paper,
    },
  };
});

const GroupedList = props => {
  const { activeId, additionalProps, className, groups = [], idKey, loading, PlaceholderComponent } = props;

  const listItemRefs = useRef({});
  const classes = useStyles(props);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (activeId) {
      // listItemRefs.current[activeId].scrollIntoView({
      //     block: 'center',
      //     behavior: 'smooth',
      // });
    }
  }, [activeId]);

  /**
   *  COMPONENTS
   */

  const list = groups.map(({ id, label, items = [], onClick, ActionsComponent, AvatarComponent, TextComponent }) => {
    return (
      <li key={id} className={classes.li}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.listSubheader}>{label}</ListSubheader>
          {items.map(item => {
            const itemId = idKey ? get(item, idKey) : item.id;

            return (
              <ListItem
                ActionsComponent={ActionsComponent}
                activeId={activeId}
                additionalProps={additionalProps}
                AvatarComponent={AvatarComponent}
                key={itemId}
                listItemRefs={listItemRefs}
                item={item}
                itemId={itemId}
                onClick={onClick}
                TextComponent={TextComponent}
              />
            );
          })}
          {items.length < 1 && PlaceholderComponent}
        </ul>
      </li>
    );
  });

  return (
    <List className={classNames(classes.root, className)} subheader={<li />}>
      {loading ? <ListSkeleton /> : list}
    </List>
  );
};

GroupedList.propTypes = {
  ActionsComponent: PropTypes.object,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  AvatarComponent: PropTypes.object,
  className: PropTypes.string,
  groups: PropTypes.array,
  idKey: PropTypes.string,
  loading: PropTypes.bool,
  PlaceholderComponent: PropTypes.node,
  TextComponent: PropTypes.object,
};

export default GroupedList;
