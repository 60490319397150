import moment from 'moment';

/**
 * Get moment locale by navigator language
 * @returns {*}
 */

export const getMomentLocale = () => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  locale && moment.locale(`${locale}`);
  return locale;
};

export default getMomentLocale;
