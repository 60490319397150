import { EMPTY_FILTER_CONFIG } from '@geomagic/geonam-graphql';

const getEntityFilter = entityClassNames => {
  return {
    ...EMPTY_FILTER_CONFIG,
    entityClassNames,
    extraFilters: [
      {
        discriminator: 'process',
        processFilter: { type: 'assignee', values: [{ operator: 'EQUALS', value: 'current:' }] },
      },
    ],
  };
};

export default getEntityFilter;
