import getUrl from './getUrl';
import { DEFAULT_NAVIGATION_URLS } from '../consts';

/**
 * Open default navigation app, based on os (user agent)
 * @param {Array} destination - Layers from GeoNAM view
 * @param {Object} config - @geomagic/map LayerSelectionStore (window.localStorage)
 */

const startNavigation = (destination, config = DEFAULT_NAVIGATION_URLS) => {
  const uA = navigator.userAgent;
  let url = getUrl(destination, config);
  if (uA.indexOf('Windows Phone') > -1) {
    url = getUrl(destination, config, 'windowsPhone');
  } else if (uA.indexOf('Android') > -1) {
    url = getUrl(destination, config, 'android');
  } else if (uA.indexOf('iPhone') > -1 || uA.indexOf('iPad') > -1 || uA.indexOf('iPod') > -1) {
    url = getUrl(destination, config, 'ios');
  }

  console.log('MOBILE MAP - Start navigation to', url);

  let target;
  if (!url.startsWith('http') || ('standalone' in window.navigator && window.navigator.standalone)) {
    target = '_self';
  } else {
    target = '_blank';
  }

  window.open(url, target);
};

export default startNavigation;
