import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const TeleporterContext = createContext(null);

const TeleporterProvider = props => {
  const [TeleporterMap, setTeleporterMap] = useState(new Map());

  const addTeleporterItem = useCallback((TeleporterType, component) => {
    TeleporterMap.set(TeleporterType, component);

    const clonedMapWithNewItem = new Map(TeleporterMap);

    setTeleporterMap(clonedMapWithNewItem);
    /* eslint-disable-next-line */
  }, []);

  const removeTeleporterItem = useCallback(TeleporterType => {
    TeleporterMap.delete(TeleporterType);

    const clonedMapWithoutItem = new Map(TeleporterMap);

    setTeleporterMap(clonedMapWithoutItem);

    /* eslint-disable-next-line */
  }, []);

  return (
    <TeleporterContext.Provider value={{ TeleporterMap, addTeleporterItem, removeTeleporterItem }}>
      {props.children}
    </TeleporterContext.Provider>
  );
};

const TeleporterIn = props => {
  const { addTeleporterItem, removeTeleporterItem } = useContext(TeleporterContext);

  useEffect(() => {
    addTeleporterItem(props.TeleporterType, props.children);

    return () => removeTeleporterItem(props.TeleporterType);
    /* eslint-disable-next-line */
  }, [props.TeleporterType, props.children]);

  return null;
};

const TeleporterOut = props => {
  const { TeleporterMap } = useContext(TeleporterContext);

  return TeleporterMap.get(props.TeleporterType) || null;
};

export { TeleporterProvider, TeleporterIn, TeleporterOut };
