/**
 * Get the layer from a root object via mongoDbId.
 *
 * @param {} id
 * @param {*} root
 * @returns
 */

const getLayer = (id, root) => {
  let found;
  root.getLayers().forEach(layer => {
    if (layer.get('mongoDbId') === id) {
      found = layer;
    }
    if (layer.getLayers) {
      const foundSub = getLayer(id, layer);
      if (foundSub) {
        found = foundSub;
      }
    }
  });
  return found;
};

export default getLayer;
