import gql from 'graphql-tag';
import FragmentDispatchCreationConfig from './FragmentDispatchCreationConfig';
import FragmentFormElementRow from './FragmentFormElementRow';

const FragmentFormElementBlock = gql`
  ${FragmentDispatchCreationConfig}
  ${FragmentFormElementRow}

  fragment FormElementBlock on FormElementBlock {
    children {
      ...FormElementRow
    }
    description
    dispatchCreationConfig {
      ...DispatchCreationConfig
    }
    dispatchesCreatable
    id
    name
    options
    relevantDispatches {
      className
      id
    }
    seqNumber
    typename
  }
`;

export default FragmentFormElementBlock;
