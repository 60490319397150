import MVT from 'ol/format/MVT';
import VectorTileSource from 'ol/source/VectorTile';
import flattenCoordinates from './flattenCoordinates';

/**
 * Get tileUrls from a vector tile source
 * @param {object} params
 * @param {array.<object>} params.features - Features
 * @param {number} params.maxZoomLevel - Max zoom level
 * @param {number} params.minZoomLevel - Min zoom level
 * @param {string} params.url - Url of the tile server (pbf)
 * @returns {Array.<string>}
 */

const getVectorTileUrls = ({ extent, features = [], maxZoomLevel, minZoomLevel, url }) => {
  let tileUrls = [];
  const tileSource = new VectorTileSource({ format: new MVT(), url });
  const tileGrid = tileSource.getTileGrid();
  const tileUrlFunction = tileSource.getTileUrlFunction();

  for (let i = minZoomLevel; i <= maxZoomLevel; i++) {
    if (extent) {
      tileGrid.forEachTileCoord(extent, i, tileCoords => {
        const tileUrl = tileUrlFunction(tileCoords, 1);
        tileUrls.push(tileUrl);
      });
    } else {
      features.forEach(({ geometry }) => {
        const flatCoordinates = flattenCoordinates(geometry);
        const tileCoords = tileGrid.getTileCoordForCoordAndZ(flatCoordinates, i);
        const tileUrl = tileUrlFunction(tileCoords, 1);
        tileUrls.push(tileUrl);
      });
    }
  }

  return tileUrls;
};

export default getVectorTileUrls;
