import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';

import { i18n } from '@geomagic/i18n';

import useAppBar from '@components/AppBar/useAppBar';
import GroupedList from '@components/GroupedList';
import Placeholder from '@components/Placeholder';
import useFeatures from '@components/Map/utils/useFeatures';
import { ENTITY_SELECTOR_KEY } from '@database/consts';

import getFeatures from './getFeatures';
import getGroups from './getGroups';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions, typography }) => ({
  root: {},
}));

const AssignmentList = props => {
  const {
    assignments,
    className,
    client,
    entityClasses,
    getFeatureStyle,
    handleCheckForOpenDrafts,
    isLoading,
    isMobile,
    isOnline,
    mapProps,
    onClick,
    onCloseSwipeableArea,
    onDeleteAssignment,
    onOpenTasks,
    onSyncAssignment,
    user,
    vectorTileServerUrl,
  } = props;
  const { mapRef, maxExtentZoomLevel, selectColor } = mapProps;

  const { setTitle } = useAppBar();
  const [activeItemId, setActiveItemId] = useState();
  const [features, setFeatures] = useState([]);
  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleSelectFeature = useCallback((event, feature) => {
    const entity = feature?.get('entity');
    setActiveItemId(prev => (entity ? entity?.id : null));
  }, []);

  const handleShowOnMap = (features, entityId) => {
    selectFeatures(features);
    setActiveItemId(entityId);
  };

  /**
   *  MAP
   */

  const { animateFeatures, selectFeatures } = useFeatures(
    { mapRef, features, maxExtentZoomLevel, selectColor, style: getFeatureStyle },
    { onSelect: handleSelectFeature }
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setTitle(i18n.t('type.assignments'));
  }, [setTitle]);

  useEffect(() => {
    if (!isLoading) {
      const _features = getFeatures(assignments);
      setFeatures(_features);
    }
  }, [assignments, isLoading]);

  useEffect(() => {
    animateFeatures();
  }, [animateFeatures, features]);

  /**
   *  COMPONENTS
   */

  const getContentComponent = () => {
    let Component;

    if (assignments.length < 1 && !isLoading) {
      Component = (
        <Placeholder
          icon={<AssignmentIcon />}
          title={i18n.t('assignment.placeholder.noAssignments.title')}
          subtitle={i18n.t('assignment.placeholder.noAssignments.subtitle')}
        />
      );
    } else {
      const groups = getGroups({ items: assignments, entityClasses, onClick });

      const additionalContextProps = {
        client,
        entityClasses,
        isMobile,
        isOnline,
        handleCheckForOpenDrafts,
        mapProps,
        onCloseSwipeableArea,
        onDeleteAssignment,
        onOpenTasks,
        onShowOnMap: handleShowOnMap,
        onSyncAssignment,
        user,
        vectorTileServerUrl,
      };

      Component = (
        <GroupedList
          className={classNames(classes.root, className)}
          activeId={activeItemId}
          additionalProps={additionalContextProps}
          groups={groups}
          idKey={ENTITY_SELECTOR_KEY}
          loading={isLoading}
        />
      );
    }

    return Component;
  };

  return getContentComponent();
};

AssignmentList.propTypes = {
  assignments: PropTypes.array.isRequired,
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getFeatureStyle: PropTypes.func.isRequired,
  isOnline: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleCheckForOpenDrafts: PropTypes.func.isRequired,
  mapProps: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseSwipeableArea: PropTypes.func.isRequired,
  onDeleteAssignment: PropTypes.func.isRequired,
  onOpenTasks: PropTypes.func.isRequired,
  onSyncAssignment: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  vectorTileServerUrl: PropTypes.string,
};

export default AssignmentList;
