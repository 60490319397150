import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/styles';

import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import { getEntityType } from '@geomagic/geonam';

const useStyles = makeStyles(({ palette, shape, spacing, typography }) => {
  const isLightTheme = palette.type === 'light';
  const chipBackground = palette.primary[isLightTheme ? 300 : 500];

  return {
    root: {
      margin: 0,
    },
    relations: {
      marginTop: spacing(0.5),
    },
    chip: {
      background: chipBackground,
      borderRadius: shape.borderRadius,
      color: palette.getContrastText(chipBackground),
      fontSize: typography.pxToRem(11),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      marginRight: 4,
      marginBottom: 4,
      maxWidth: '50%',
      height: 20,
    },
    chipLabel: {
      paddingLeft: 4,
      paddingRight: 4,
    },
  };
});

const DispatchListText = props => {
  const { data, entityClasses } = props;
  const { relations } = data.toJSON();
  const entity = data.getPatchedEntity();
  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const label = data?.draft ? entityType?.name : entity?.displayName;

  const classes = useStyles();

  /**
   *  COMPONENTS
   */

  const getRelationComponent = () => {
    return (
      <div className={classes.relations}>
        {Object.values(relations).map(item => (
          <Chip
            key={item.id}
            classes={{ root: classes.chip, label: classes.chipLabel }}
            label={item.name}
            size="small"
          />
        ))}
      </div>
    );
  };

  return (
    <ListItemText
      className={classes.root}
      primary={label}
      secondary={
        <React.Fragment>
          {!relations && entity?.shortDescription}
          {relations && getRelationComponent()}
        </React.Fragment>
      }
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondaryTypographyProps={{ component: 'div', noWrap: true, variant: 'body2' }}
    />
  );
};

DispatchListText.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default memo(DispatchListText, (prev, next) => deepEqual(prev, next));
