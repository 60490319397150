import gql from 'graphql-tag';

const MutationUpdateFormElement = gql`
  mutation MutationUpdateFormElement($formElement: FormElementInput!) {
    updateFormElement(formElement: $formElement) {
      id
      name
    }
  }
`;

export default MutationUpdateFormElement;
