import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/styles';

import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';

import EntityAvatar from '@geomagic/nam-react-core/components/EntityAvatar';
import EntityState from '@geomagic/nam-react-core/components/EntityState';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    minWidth: 56,
  },
  badge: {
    background: palette.info.main,
    color: palette.getContrastText(palette.info.main),
    padding: 0,
  },
  closeIcon: {
    fontSize: typography.pxToRem(16),
  },
}));

const DispatchListAvatar = props => {
  const { data, entityClasses } = props;
  const entity = data.getPatchedEntity();
  const isDraftClosed = data?.draft?.closed;

  const classes = useStyles();

  /**
   *  GENERAL PROPS
   */

  const badgeProps = {
    anchorOrigin: { horizontal: 'right', vertical: isDraftClosed ? 'top' : 'bottom' },
    badgeContent: isDraftClosed ? (
      <CheckIcon className={classes.closeIcon} />
    ) : (
      <EntityState entity={entity} size="small" variant="circle" />
    ),
    ...(isDraftClosed && { classes: { badge: classes.badge } }),
  };

  return (
    <div className={classes.root}>
      <Badge overlap="circular" {...badgeProps}>
        <EntityAvatar entity={entity} entityClasses={entityClasses} />
      </Badge>
    </div>
  );
};

DispatchListAvatar.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
};

export default memo(DispatchListAvatar, (prev, next) => deepEqual(prev, next));
