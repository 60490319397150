import React, { Fragment } from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export default createSvgIcon(
  <Fragment>
    <path d="M3.2,16.86A1,1,0,0,0,3,18.25a1,1,0,0,0,.82.44,1,1,0,0,0,.57-.18A1,1,0,0,0,3.2,16.86Z" />
    <path d="M4.69,3.78a1,1,0,1,0,1.41.08A1,1,0,0,0,4.69,3.78Z" />
    <path d="M7.82,20a1,1,0,0,0-.93,1.77,1,1,0,0,0,.46.12A1,1,0,0,0,7.82,20Z" />
    <path d="M9.34,1.32a1,1,0,0,0,.24,2,1,1,0,0,0,1-1.24A1,1,0,0,0,9.34,1.32Z" />
    <path d="M3.06,13.09a1,1,0,1,0-1,1.12h.12A1,1,0,0,0,3.06,13.09Z" />
    <path d="M2.29,9.4a1,1,0,0,0,.35.06A1,1,0,0,0,3,7.53a1,1,0,0,0-.7,1.87Z" />
    <path d="M21.34,9.43a.92.92,0,0,0,.36-.07A1,1,0,0,0,21,7.49a1,1,0,0,0,.36,1.94Z" />
    <path d="M12,21H12a1,1,0,0,0,0,2h0a1,1,0,1,0,0-2Z" />
    <path d="M22,12.18a1,1,0,0,0-1.1.88h0a1,1,0,0,0,.88,1.11h.12a1,1,0,0,0,.11-2Z" />
    <path d="M19.27,3.75A1,1,0,0,0,18,5.25a1,1,0,0,0,.66.25,1,1,0,0,0,.66-1.75Z" />
    <path d="M19.42,17.09a1,1,0,0,0,.26,1.39,1,1,0,0,0,.57.17,1,1,0,1,0-.83-1.56Z" />
    <path d="M16.21,20h0a1,1,0,0,0,.47,1.89A1,1,0,1,0,16.21,20Z" />
    <path d="M14.61,1.31a1,1,0,0,0-.48,1.94,1,1,0,0,0,1.21-.73A1,1,0,0,0,14.61,1.31Z" />
  </Fragment>,
  'Dispatch'
);
