import moment from 'moment';
import { TIMESTAMP_DISPLAY_FORMAT } from '@geomagic/geonam';
import { DATE_DISPLAY_FORMAT } from '@consts';

import convertDateFormat from './convertDateFormat';
import getMomentLocale from './getMomentLocale';

/**
 * Get picker props from an entity
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {Object} entity - GeoNAM entity
 * @returns {Object}
 */

const getDateFormatObject = (attributeType, entity) => {
  const { options } = attributeType;
  const locale = getMomentLocale();
  const format =
    options && options.pattern
      ? convertDateFormat(options.pattern)
      : attributeType.dataType === 'DATE'
      ? locale
        ? moment.localeData().longDateFormat('L')
        : DATE_DISPLAY_FORMAT
      : locale
      ? moment.localeData().longDateFormat('LT')
      : TIMESTAMP_DISPLAY_FORMAT;
  return {
    pickerProps: {
      format,
      ...(options &&
        options.minRelDays && {
          minDate: entity
            ? moment(entity.auditInfo.createdOn).add(options.minRelDays, 'd')
            : moment().add(options.minRelDays, 'd'),
        }),
      ...(options &&
        options.maxRelDays && {
          maxDate: entity
            ? moment(entity.auditInfo.createdOn).add(options.maxRelDays, 'd')
            : moment().add(options.maxRelDays, 'd'),
        }),
    },
  };
};

export default getDateFormatObject;
