import { easeOut } from 'ol/easing';
import { unByKey } from 'ol/Observable';
import { Circle as CircleStyle, Stroke, Style } from 'ol/style';
import { getVectorContext } from 'ol/render';
// import { paletteLight } from '../../themePalette';
// import hexToRgba from './hexToRgba';

/**
 * Animates a specific feature with a flash
 * @param {Object} params
 * @param {Object} params.map - Openlayers map
 * @param {Object} params.layer - Layer of the feature
 * @param {string} params.featureId - FeatureId
 * @returns {void}
 */

const flashFeature = ({ map, layer, feature, selectColor }) => {
  const flash = ({ map, layer, feature }) => {
    if (!feature) return;

    map.updateSize();

    const duration = 3000;
    const start = new Date().getTime();
    const listenerKey = layer.on('postrender', animate);

    function animate(event) {
      const vectorContext = getVectorContext(event);
      const frameState = event.frameState;
      const flashGeom = feature.getGeometry().clone();
      const elapsed = frameState.time - start;
      if (elapsed > duration) {
        unByKey(listenerKey);
        return;
      }
      const elapsedRatio = elapsed / duration;
      const opacity = easeOut(1 - elapsedRatio);
      const radius = easeOut(elapsedRatio) * 25 + 5;

      const style = new Style({
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: selectColor,
            width: 0.3 + opacity,
          }),
        }),
      });

      vectorContext.setStyle(style);
      vectorContext.drawGeometry(flashGeom);

      // tell OpenLayers to continue postrender animation
      map.render();
    }
  };

  flash({ map, layer, feature });
};

export default flashFeature;
