import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

import { i18n } from '@geomagic/i18n';

import { FORM_COMPONENT_CHILDREN_KEY } from './consts';
import FormPage from './FormElementPage';
import FormElementBlock from './FormElementBlock';
import FormElementRow from './FormElementRow';
import FormElement from './FormElement';
import MarkDownTrigger from './MarkDownTrigger';

import isMarkdownDescriptionType from './utils/isMarkdownDescriptionType';

const getElementByTypename = ({ typename }) => {
  let component;

  switch (typename) {
    case 'Form':
      component = FormPage;
      break;
    case 'FormElementBlock':
      component = FormElementBlock;
      break;
    case 'FormElementRow':
      component = FormElementRow;
      break;
    default:
      component = FormElement;
  }

  return component;
};

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions }) => ({
  markDownTrigger: {
    marginLeft: spacing(0.5),
  },
}));

const FormComponent = props => {
  const classes = useStyles();
  const { context, data, doc, isDefectStatusSelected, path, ...elementProps } = props;
  const { isMobile } = context;
  const { children = [], description, displayedIfDefect, name } = data;

  const isElementDisplayed = !displayedIfDefect || isDefectStatusSelected;

  const Component = getElementByTypename(data);
  const isMarkdown = isMarkdownDescriptionType(data);

  const infoTrigger = isMarkdown && (
    <MarkDownTrigger
      className={classes.markDownTrigger}
      icon={<HelpIcon />}
      isMobile={isMobile}
      source={description}
      title={i18n.t('assignment.dialog.description.title', { variables: { name } })}
      variant="icon"
    />
  );

  return isElementDisplayed ? (
    <Component
      context={context}
      data={data}
      doc={doc}
      infoTrigger={infoTrigger}
      isDefectStatusSelected={isDefectStatusSelected}
      path={path}
      {...elementProps}
    >
      {children?.map((child, idx) => (
        <FormComponent
          key={child.id}
          id={child.id}
          context={context}
          data={child}
          doc={doc}
          path={`${path}/${FORM_COMPONENT_CHILDREN_KEY}/${idx}`}
        />
      ))}
    </Component>
  ) : null;
};

FormComponent.propTypes = {
  context: PropTypes.object,
  data: PropTypes.object,
  isDefectStatusSelected: PropTypes.bool,
  path: PropTypes.string,
};

export default memo(FormComponent, (prev, next) => deepEqual(prev, next));
