/**
 * The Dialog component returns a customized dialog window.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { ContentRoot } from '@geomagic/layout';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }) => ({
  dialog: { display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' },
  dialogTitle: {
    borderBottom: `1px solid ${palette.divider}`,
  },
  dialogContent: {},
  dialogActions: {
    borderTop: `1px solid ${palette.divider}`,
  },
  paper: {
    flex: 1,
  },
}));

const CustomDialog = props => {
  const classes = useStyles(props);
  const {
    actions,
    classes: dialogClasses,
    content,
    handleClose,
    maxWidth = 'sm',
    open = false,
    scrollableContent = true,
    title,
    ...dialogProps
  } = props;

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={maxWidth}
      onClose={handleClose}
      onClick={evt => {
        evt.stopPropagation();
      }}
      open={open}
      PaperProps={{ className: classes.paper }}
      {...dialogProps}
    >
      {title ? <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle> : null}
      {content ? (
        scrollableContent ? (
          <ContentRoot withPadding={false} withCustomScrollbar>
            <DialogContent className={classes.dialogContent}>{content}</DialogContent>
          </ContentRoot>
        ) : (
          <DialogContent className={classes.dialogContent}>{content}</DialogContent>
        )
      ) : null}

      {actions ? <DialogActions className={classes.dialogActions}>{actions}</DialogActions> : null}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  handleClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default CustomDialog;
