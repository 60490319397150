import { getReference } from '@geomagic/geonam';

import getUpdatedDoc from '@database/getUpdatedDoc';
import handleFetchError from '@database/handleFetchError';
import handleSyncError from '@database/handleSyncError';
import resetDoc from '@database/resetDoc';

import updateFormElement from './updateFormElement';
import updateRelevantDispatches from './updateRelevantDispatches';

const getAssignmentSync = async ({
  client,
  database,
  doc,
  entityClasses,
  mapProps,
  refetchQuery,
  selector,
  transform,
}) => {
  const syncErrors = [];
  let entityReference = getReference(doc?.entity);

  try {
    const updateProps = {
      client,
      database,
      doc,
      entityClasses,
      entity: doc?.entity,
      mapProps,
      patchedEntity: doc.getPatchedEntity(),
      selector,
      syncErrors,
    };

    await updateRelevantDispatches(updateProps);
    await updateFormElement(updateProps);
    const updatedEntity = refetchQuery && (await getUpdatedDoc(client, refetchQuery, entityReference, mapProps));

    if (updatedEntity) {
      const entity = transform && (await transform(database, updatedEntity));
      await resetDoc(doc, entity);
    }
  } catch (error) {
    handleFetchError({ error, isDefaultThrow: false });
    handleSyncError({ entityReference, error, syncErrors });
  }
};

export default getAssignmentSync;
