import getLayer from './getLayer';
/**
 * Is a layer visible.
 * @param {*} id
 * @param {*} map
 * @returns
 */
const isLayerVisible = (id, map) => {
  const layer = getLayer(id, map);
  if (layer) {
    return layer.getVisible();
  }
};

export default isLayerVisible;
