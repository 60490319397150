import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import debounce from 'lodash/debounce';
import { transform } from 'ol/proj';
import { i18n } from '@geomagic/i18n';
import Geolocation from 'ol/Geolocation';

import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import {
  CALIBRATION_WARNING_KEY,
  DEFAULT_GEOLOCATION_PROJECTION,
  DEFAULT_GEOLOCATION_SETTINGS,
  ORIENTATION_GRANTED_KEY,
  POSITION_SETTINGS_KEY,
} from './consts';
import useTrackLocationFeature from './useTrackLocationFeature';

const geolocation = new Geolocation(DEFAULT_GEOLOCATION_SETTINGS);

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    marginBottom: spacing(),
    pointerEvents: 'auto',
  },
  button: {
    width: 32,
    minWidth: 32,
    height: 32,
    borderRadius: 0,
  },
  icon: {
    fontSize: 18,
  },
}));

const DEFAULT_LOCATION_STATE = {
  accuracy: 0,
  location: null,
  rotation: '0',
};

const ACCURANCY_ORIENTATION = 3;
//https://stackoverflow.com/questions/11060869/javascript-deviceorientation-event-what-sensors-does-it-measure
const ALPHA_SMOOTHING_FACTOR = 0.25;
const ABSOLUT_DEVICE_ORIENTATION = 'deviceorientationabsolute';
const CALIBRATION_SNACKBAR_KEY = 'calibrationWarning';
const DEFAULT_DEVICE_ORIENTATION = 'deviceorientation';
const THROTTLING_IN_MS = 200;

// convert degrees to radians
const degToRad = deg => {
  return (deg * Math.PI * 2) / 360;
};

const LocationTracking = props => {
  const { className, isFollowTracking, rotateViewByOrientation, map, targetZoomOnTracking = 15 } = props;

  const [positionSettings, setPositionSettings] = useStickySessionState(POSITION_SETTINGS_KEY, {
    isTracking: false,
    moveLocation: false,
  });
  const { isTracking, moveLocation } = positionSettings;

  const [deviceOrientationEventType, setDeviceOrientationEventType] = useState(DEFAULT_DEVICE_ORIENTATION);
  const [isCalibrationWarningDisplayed, setCalibrationWarningDisplayed] = useStickySessionState(
    CALIBRATION_WARNING_KEY,
    false
  );
  const [isOrientationGranted, setOrientationGranted] = useStickySessionState(ORIENTATION_GRANTED_KEY, false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [trackLocation, setTrackLocation] = useState(DEFAULT_LOCATION_STATE);

  const { accuracy, location, rotation } = trackLocation;

  const alphaRef = useRef(0);
  const hasAbsoluteDeviceOrientationBeenUsed = useRef(false);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const classes = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleChangePositionSettings = useCallback(
    newPositionSettings => {
      setPositionSettings(oldPositionSettings => ({
        ...oldPositionSettings,
        ...newPositionSettings,
      }));
    },
    [setPositionSettings]
  );

  const handleChangeTrackLocation = useCallback(
    newTrackLocation => {
      setTrackLocation(oldTrackLocation => ({ ...oldTrackLocation, ...newTrackLocation }));
    },
    [setTrackLocation]
  );

  const getTrackingOverlay = useCallback(() => {
    const layers = map.getLayers().getArray();

    return layers.find(layer => layer.get('name') === 'careTrackingOverlay');
  }, [map]);

  /**
   *  TRACKING EVENT HANDLER
   */

  const disableTracking = useCallback(() => {
    setIsInitialized(false);
    handleChangePositionSettings({
      isTracking: false,
      moveLocation: false,
    });

    setTrackLocation(DEFAULT_LOCATION_STATE);
  }, [handleChangePositionSettings]);

  const toggleTracking = useCallback(() => {
    if (!isTracking) {
      handleChangePositionSettings({
        isTracking: true,
        moveLocation: isFollowTracking,
      });
      if (
        window.DeviceOrientationEvent &&
        typeof window.DeviceOrientationEvent.requestPermission === 'function' &&
        !isOrientationGranted
      ) {
        window.DeviceOrientationEvent.requestPermission().then(permissionState => {
          if (permissionState === 'granted') {
            setOrientationGranted(true);
          }
        });
      }
    } else {
      disableTracking();
    }
  }, [
    disableTracking,
    isFollowTracking,
    isOrientationGranted,
    isTracking,
    handleChangePositionSettings,
    setOrientationGranted,
  ]);

  /**
   *  MAP EVENT HANDLER
   */

  const centerLocation = useCallback(
    location => {
      const view = map.getView();
      view.setCenter(location);
    },
    [map]
  );

  const followCenter = useCallback(
    location => {
      const view = map.getView();
      const [locationLon, locationLat] = location;

      if (locationLon !== 0 || locationLat !== 0) {
        const transformedLocation = transform(location, DEFAULT_GEOLOCATION_PROJECTION, map.getView().getProjection());
        const [transformedLon, transformedLat] = transformedLocation;
        const [centerLon, centerLat] = view.getCenter();

        if (centerLon !== transformedLon || centerLat !== transformedLat) {
          const debouncedCenter = debounce(() => centerLocation(transformedLocation), 100);
          debouncedCenter();
        }
      }
    },
    [centerLocation, map]
  );

  const initialCenter = useCallback(
    location => {
      const view = map.getView();
      const [locationLon, locationLat] = location;

      if (locationLon !== 0 || locationLat !== 0) {
        const transformedLocation = transform(location, DEFAULT_GEOLOCATION_PROJECTION, view.getProjection());
        const [transformedLon, transformedLat] = transformedLocation;
        const [centerLon, centerLat] = view.getCenter();

        if (centerLon !== transformedLon || centerLat !== transformedLat) {
          centerLocation(transformedLocation);
          view.setZoom(targetZoomOnTracking);
          setIsInitialized(true);
        }

        if (centerLon === transformedLon && centerLat === transformedLat) {
          view.setZoom(targetZoomOnTracking);
          setIsInitialized(true);
        }
      }
    },
    [centerLocation, map, targetZoomOnTracking]
  );

  const handleChangeRotation = useCallback(
    newHeading => {
      const view = map.getView();
      if (!isNaN(parseFloat(newHeading))) {
        const trackedNum = Number.parseFloat(newHeading).toFixed(ACCURANCY_ORIENTATION);
        const currentRotationNum = Number.parseFloat(view.getRotation()).toFixed(ACCURANCY_ORIENTATION);

        if (trackedNum !== currentRotationNum) {
          handleChangeTrackLocation({ rotation: trackedNum });
        }
      }
    },
    [handleChangeTrackLocation, map]
  );

  const handleChangePosition = useCallback(
    (location, accuracy) => {
      if (location) {
        handleChangeTrackLocation({ accuracy, location });

        if (!isInitialized) {
          initialCenter(location);
        }

        if (moveLocation) {
          followCenter(location);
        }
      }
    },
    [followCenter, isInitialized, initialCenter, moveLocation, handleChangeTrackLocation]
  );

  const throttledChangeLocation = useMemo(() => throttle(handleChangePosition, THROTTLING_IN_MS), [
    handleChangePosition,
  ]);
  const throttledChangeRotation = useMemo(() => throttle(handleChangeRotation, THROTTLING_IN_MS), [
    handleChangeRotation,
  ]);

  /**
   *  CHANGE EVENT HANDLER
   */

  const handleChangeLocation = useCallback(() => {
    const accuracy = geolocation.getAccuracy();
    const location = geolocation.getPosition();

    if (accuracy < 1000) {
      throttledChangeLocation(location, accuracy);
    }
  }, [throttledChangeLocation]);

  const handleNotCalibrated = useCallback(
    event => {
      if (!isCalibrationWarningDisplayed) {
        enqueueSnackbar(i18n.t('notification.calibrationWarning'), {
          key: 'calibrationWarning',
          preventDuplicate: true,
          persist: true,
          variant: 'warning',
        });
        setCalibrationWarningDisplayed(true);
      }
    },
    [enqueueSnackbar, isCalibrationWarningDisplayed, setCalibrationWarningDisplayed]
  );

  const handleChangeOrientation = useCallback(
    event => {
      let heading = null;
      const { absolute, alpha, webkitCompassHeading, webkitCompassAccuracy } = event;

      if (!isNaN(webkitCompassHeading)) {
        if (webkitCompassAccuracy === -1) {
          handleNotCalibrated();
        } else if (webkitCompassAccuracy < 50) {
          if (isCalibrationWarningDisplayed) {
            closeSnackbar(CALIBRATION_SNACKBAR_KEY);
          }
          heading = -degToRad(webkitCompassHeading);
        }
      } else if (
        !absolute &&
        'ondeviceorientationabsolute' in window &&
        !hasAbsoluteDeviceOrientationBeenUsed.current
      ) {
        //Try to use absolute orientation if available
        setDeviceOrientationEventType(ABSOLUT_DEVICE_ORIENTATION);
        hasAbsoluteDeviceOrientationBeenUsed.current = true;
      } else if (alpha && !isNaN(alpha)) {
        const newAlpha = alpha + ALPHA_SMOOTHING_FACTOR * (alphaRef.current - alpha);
        alphaRef.current = newAlpha;
        heading = degToRad(newAlpha);
      } else if (hasAbsoluteDeviceOrientationBeenUsed.current) {
        //Fallback to device orientation without absolute values
        setDeviceOrientationEventType(DEFAULT_DEVICE_ORIENTATION);
      }

      if (heading) {
        throttledChangeRotation(heading);
      }
    },
    [closeSnackbar, isCalibrationWarningDisplayed, handleNotCalibrated, throttledChangeRotation]
  );

  const handleChangeResolution = useCallback(
    event => {
      const location = geolocation.getPosition();

      if (location && moveLocation) {
        followCenter(location);
      }
    },
    [followCenter, moveLocation]
  );

  const handleDragMap = useCallback(() => {
    if (moveLocation) {
      handleChangePositionSettings({ moveLocation: false });
    }
  }, [moveLocation, handleChangePositionSettings]);

  useTrackLocationFeature({
    accuracy,
    location,
    getTrackingOverlay,
    isVisible: isTracking && !!location,
    map,
    rotation: rotateViewByOrientation ? '0' : -rotation,
  });

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (isTracking) {
      geolocation.setTracking(true);
    } else {
      const trackingOverlay = getTrackingOverlay();

      geolocation.setTracking(false);
      trackingOverlay.getSource().clear();
    }
  }, [isTracking, getTrackingOverlay]);

  useEffect(() => {
    if (isTracking) {
      geolocation.on('change', handleChangeLocation);

      if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
        if (isOrientationGranted) window.addEventListener(deviceOrientationEventType, handleChangeOrientation);
      } else {
        window.addEventListener(deviceOrientationEventType, handleChangeOrientation);
      }

      return () => {
        geolocation.un('change', handleChangeLocation);
        window.removeEventListener(deviceOrientationEventType, handleChangeOrientation);
        throttledChangeLocation.cancel();
        throttledChangeRotation.cancel();
      };
    }
  }, [
    deviceOrientationEventType,
    handleChangeOrientation,
    handleChangeLocation,
    isOrientationGranted,
    isTracking,
    throttledChangeLocation,
    throttledChangeRotation,
  ]);

  useEffect(() => {
    const view = map.getView();

    view.on('change:resolution', handleChangeResolution);
    map.on('pointerdrag', handleDragMap);

    return () => {
      view.un('change:resolution', handleChangeResolution);
      map.un('pointerdrag', handleDragMap);
    };
  }, [handleDragMap, handleChangeResolution, map]);

  useEffect(() => {
    if (isTracking) {
      if (rotateViewByOrientation) {
        const view = map.getView();
        view.animate({ rotation });
      }
    } else {
      const view = map.getView();
      view.animate({ rotation: 0 });
    }
  }, [isTracking, map, rotation, rotateViewByOrientation]);

  return (
    <Paper className={classNames(classes.root, className)} square>
      <Button className={classes.button} color={isTracking ? 'secondary' : 'inherit'} onClick={toggleTracking}>
        {moveLocation ? <GpsFixedIcon className={classes.icon} /> : <LocationSearchingIcon className={classes.icon} />}
      </Button>
    </Paper>
  );
};

LocationTracking.propTypes = {
  className: PropTypes.string,
  isFollowTracking: PropTypes.bool,
  map: PropTypes.object.isRequired,
  rotateViewByOrientation: PropTypes.bool,
  targetZoomOnTracking: PropTypes.number,
};

export default LocationTracking;
