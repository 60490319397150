import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import ImageIcon from '@material-ui/icons/CropOriginal';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(({ breakpoints, palette, shape, spacing, typography }) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
  },
  defaultImage: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
}));

const ImageCarousel = props => {
  const classes = useStyles(props);
  const { activeStep, className, images, onChangeStep, onClose, swipeProps } = props;

  return (
    <AutoPlaySwipeableViews
      className={classNames(classes.root, className)}
      containerStyle={{ width: '100%', height: '100%', alignItems: 'center' }}
      autoplay={false}
      index={activeStep}
      onChangeIndex={onChangeStep}
      slideStyle={{ width: '100%', height: '100%' }}
      {...swipeProps}
    >
      {images.map((image, index) => {
        const { id, url, name } = image;

        return (
          <div key={`${id}_${index}`} className={classNames(classes.container)} onClick={onClose}>
            {Math.abs(activeStep - index) <= 2 ? (
              url ? (
                <img className={classes.image} src={url} alt={name} />
              ) : (
                <div className={classes.defaultImage}>
                  <ImageIcon color="action" fontSize="large" />
                </div>
              )
            ) : null}
          </div>
        );
      })}
    </AutoPlaySwipeableViews>
  );
};

ImageCarousel.propTypes = {
  activeStep: PropTypes.number,
  classes: PropTypes.object,
  className: PropTypes.string,
  images: PropTypes.array,
  onChangeStep: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  swipeProps: PropTypes.object,
};

export default ImageCarousel;
