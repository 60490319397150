import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import RouteIcon from '@material-ui/icons/Timeline';
import GeoJSON from 'ol/format/GeoJSON';

import { i18n } from '@geomagic/i18n';

import GroupedList from '@components/GroupedList';
import addVectorLayer from '@components/Map/utils/addVectorLayer';
import addFeaturesToLayer from '@components/Map/utils/addFeaturesToLayer';
import centerView from '@components/Map/utils/centerView';
import getFeatureStyle from '@components/Map/utils/getFeatureStyle';
import Placeholder from '@components/Placeholder';
import useShowPrompt from '@utils/useShowPrompt';

import LocationRecordListActions from './LocationRecordListActions';
import LocationRecordListText from './LocationRecordListText';

const centerViewByGeometryType = (view, feature) => {
  if (feature) {
    let center;

    const geometry = feature.getGeometry();
    const coordinates = geometry.getCoordinates();
    const type = geometry.getType();

    if (type === 'Point') {
      center = coordinates;
    }

    if (type === 'LineString') {
      center = coordinates[coordinates.length - 1];
    }

    centerView({
      view,
      coordinates: center,
    });
  }
};

const getDefaultFeatureStyle = markingColor => feature => {
  return getFeatureStyle({ backgroundColor: markingColor, feature });
};

const LocationRecordList = props => {
  const { activeRecordId, isLoading, mapProps = {}, onDelete, records } = props;
  const { mapRef, markingColor } = mapProps;

  const layerRef = useRef();
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const [activeId, setActiveId] = useState();

  /**
   *  EVENT HANDLER
   */

  const getGeoJSONFromRecord = useCallback(({ geolocationPositions: positions }) => {
    let geometry;

    if (positions.length === 1) {
      geometry = {
        type: 'Point',
        coordinates: getCoordinatesFromPosition(positions[0]),
      };
    } else {
      geometry = {
        type: 'LineString',
        coordinates: positions.map(getCoordinatesFromPosition),
      };
    }

    return {
      type: 'Feature',
      geometry,
    };
  }, []);

  const getCoordinatesFromPosition = position => {
    return [position?.coords.longitude, position?.coords.latitude];
  };

  const handleClickDelete = (record, title) => {
    if (record?.id === activeRecordId) {
      enqueueSnackbar(i18n.t('locationRecording.notification.deleteRecordNotPossible'), {
        key: 'deleteRecordNotPossible',
        preventDuplicate: true,
        variant: 'info',
      });
    } else {
      showPrompt({
        title: i18n.t('locationRecording.dialog.deleteRecord.title', { variables: { record: title } }),
        content: i18n.t('locationRecording.dialog.deleteRecord.content'),
        onOk: handleDeleteRecord(record),
      });
    }
  };

  const handleDeleteRecord = item => async () => {
    const filteredRecords = records.filter(record => record.id !== item.id);
    handleRemoveLayer();
    onDelete && onDelete(filteredRecords);

    enqueueSnackbar(i18n.t('locationRecording.notification.deletedRecord'), {
      key: 'deletedRecord',
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const handleRemoveLayer = useCallback(() => {
    const map = mapRef?.current.map;
    map && map.removeLayer(layerRef.current);
  }, [mapRef]);

  const handleShowOnMap = record => {
    if (!record?.id || record.id === activeId) {
      setActiveId(null);
    } else {
      setActiveId(record.id);
    }
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const map = mapRef?.current.map;

    if (map && activeId) {
      const layer = addVectorLayer(map, getDefaultFeatureStyle(markingColor));
      layerRef.current = layer;
    }

    return () => {
      handleRemoveLayer();
    };
  }, [activeId, handleRemoveLayer, mapRef, markingColor]);

  useEffect(() => {
    const map = mapRef?.current.map;
    const layer = layerRef.current;

    if (activeId) {
      const record = records.find(record => record.id === activeId);

      if (map && layer && record) {
        const view = map.getView();
        const featureProjection = view.getProjection().getCode();
        const geoJSON = getGeoJSONFromRecord(record);
        const feature = new GeoJSON({ featureProjection }).readFeature(geoJSON);

        addFeaturesToLayer(layer, [feature]);
        centerViewByGeometryType(view, feature);
      }
    }
  }, [activeId, getGeoJSONFromRecord, mapRef, records]);

  /**
   *  GROUP
   */

  const groups = [
    {
      id: 'savedRoutes',
      label: i18n.t('locationRecording.label.savedRoutes'),
      items: records,
      ActionsComponent: LocationRecordListActions,
      TextComponent: LocationRecordListText,
      onClick: handleShowOnMap,
    },
  ];

  const additionalProps = {
    onDeleteRecord: handleClickDelete,
    onShowOnMap: handleShowOnMap,
  };

  return (
    <GroupedList
      activeId={activeId}
      additionalProps={additionalProps}
      groups={groups}
      loading={isLoading}
      PlaceholderComponent={
        <Placeholder
          icon={<RouteIcon />}
          iconSize={80}
          title={i18n.t('locationRecording.placeholder.noRecords.title')}
          subtitle={i18n.t('locationRecording.placeholder.noRecords.subtitle')}
        />
      }
    />
  );
};

LocationRecordList.propTypes = {
  activeRecordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mapProps: PropTypes.object.isRequired,
  mapRef: PropTypes.object,
  onDelete: PropTypes.func,
  records: PropTypes.array.isRequired,
};

export default LocationRecordList;
