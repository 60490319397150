import uuidv4 from 'uuid/v4';
import { getReference } from '@geomagic/geonam';

/**
 * Get an template for draft
 * @param {Object} entityParams
 * @param {Object} draftParams
 * @param {Object} entityParams.entityReference - GeoNAM entity reference
 * @param {Object} entityParams.entityType - GeoNAM entity type
 * @returns {Object}
 */

const getEntityTemplate = (entityClass, entityTypeId) => {
  if (entityClass) {
    const { className, entityTypes } = entityClass;
    const filteredEntityTypes = entityTypes.filter(({ creatable }) => creatable);
    const entityType = entityTypeId
      ? filteredEntityTypes.find(entityType => entityType.id === entityTypeId)
      : filteredEntityTypes.length > 0
      ? filteredEntityTypes[0]
      : null;

    return {
      id: uuidv4(),
      attributeValues: [],
      checklistItems: [],
      className,
      documents: [],
      entityType: entityType ? getReference(entityType) : null,
      featureCollections: [],
      journal: [],
    };
  } else {
    console.error('ENTITYCLASS IS REQUIRED');
  }
};

export default getEntityTemplate;
