import GeoJSON from 'ol/format/GeoJSON';

/**
 * Get the features of a featureCollection
 * @param {Object} map - Openlayers map
 * @param {Object} featureCollection - GeoNAM featureCollection
 * @returns {Array}
 */

const getFeatures = (map, features) => {
  let geoJSONFeatures = [];
  const featureProjection = map.getView().getProjection();
  const hasFeatures = features.length > 0;

  if (hasFeatures) {
    const dataProjection = 'EPSG:' + features[0].srid;
    console.log('dataProjection', dataProjection);
    console.log('featureProjection', featureProjection);
    const geoJSON = new GeoJSON({
      dataProjection,
      featureProjection,
    });
    geoJSONFeatures = features.map(feature => geoJSON.readFeature(feature));
  }

  return geoJSONFeatures;
};

export default getFeatures;
