import MutationConnectDispatchFormElementBlock from '@graphql/mutations/MutationConnectDispatchFormElementBlock';
import QueryDispatch from '@graphql/queries/QueryDispatch';

import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getDefaultSync from '@database/getDefaultSync';
import defaultSelector from '@database/selector';
import getDiffs from '@database/getDiffs';

const DISPATCH_KEY = 'relevantDispatches';
const UPDATE_KEY = 'id';

const updateRelevantDispatches = async ({
  client,
  customSelector,
  database,
  doc,
  entity,
  entityClasses,
  mapProps,
  patchedEntity,
}) => {
  const relevantDispatches = entity[DISPATCH_KEY];
  const patchedRelevantDispatches = patchedEntity[DISPATCH_KEY];

  if (patchedRelevantDispatches?.length > 0) {
    const { added } = getDiffs(relevantDispatches, patchedRelevantDispatches, UPDATE_KEY);

    if (added.length > 0) {
      for (let i = 0; i < added.length; i++) {
        try {
          const relevantDispatch = added[i];
          const selector = { [ENTITY_SELECTOR_KEY]: relevantDispatch.id, ...defaultSelector };
          const dispatchDoc = await database.dispatches.findOne({ selector }).exec();

          if (dispatchDoc) {
            const createdEntityReference = await getDefaultSync({
              client,
              doc: dispatchDoc,
              entityClasses,
              mapProps,
              refetchQuery: QueryDispatch,
            });

            if (createdEntityReference && relevantDispatch?.blockId) {
              await client.mutate({
                mutation: MutationConnectDispatchFormElementBlock,
                variables: {
                  dispatchReference: createdEntityReference,
                  formElementBlockId: relevantDispatch?.blockId,
                },
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

export default updateRelevantDispatches;
