import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Group from 'ol/layer/Group';

import { getLayerStoreInstance, getRootLayerGroup } from '@geomagic/map';

import GroupListItem from './GroupListItem';
import LayerListItem from './LayerListItem';
import isLayerVisible from './utils/isLayerVisible';
import setLayerVisible from './utils/setLayerVisible';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(),
    pointerEvents: 'auto',
  },
  subheader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    paddingTop: 0,
  },
  button: {
    flex: 1,
    marginRight: spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  listPadding: {
    paddingLeft: '0px',
  },
  list: {
    width: '100%',
  },
}));

const getNestedIds = layer => {
  const layerIds = [];
  getNestedIdsLayers(layerIds, layer);

  return layerIds;
};
const getNestedIdsLayers = (layerIds, layer) => {
  if (layer instanceof Group) {
    layerIds.push(layer.get('mongoDbId'));
    layer
      .getLayers()
      .getArray()
      .forEach(l => {
        getNestedIdsLayers(layerIds, l);
      });
  } else {
    layerIds.push(layer.get('mongoDbId'));
  }
};

const isLayerInRoot = (layers, layerId) => {
  return layers.map(layer => layer.get('mongoDbId')).includes(layerId);
};

const getLayerStructure = (layers, olLayers, parent) => {
  return layers.reduce((acc, layer) => {
    const { id, groupName, parent: layerParent, selectAll } = layer;
    if ((!parent && !layerParent) || parent === layerParent) {
      const olLayer = olLayers.find(item => item.get('mongoDbId') === id);
      acc.push({
        id,
        childs: olLayer instanceof Group ? getLayerStructure(layers, olLayer.getLayers().getArray(), id) : 0,
        olLayer,
        groupName,
        selectAll,
      });
    }
    return acc;
  }, []);
};

const findLayerInArray = (layers, layerId) => {
  return layers.find(layer => layer.layerId === layerId);
};

/**
 * Search for the parent and ancestors of this parent layer in a structure recursively.
 *
 * Set the visibility to the new child value and adjacent layers.
 *
 * @param {object} map
 * @param {array} layerStructure
 * @param {string} layerParentID
 * @returns {array} parentLayers
 */
const searchForParentLayersAndSetVisibility = (map, layerStructure, layerParentID) => {
  const parentLayers = [];
  const descendantsVisibility = [];

  layerStructure.forEach(layer => {
    let isParentFound = false;
    const { childs, id: layerId } = layer;

    if (childs) {
      if (layerId === layerParentID) {
        isParentFound = true;
      }

      if (!isParentFound) {
        const descendants = searchForParentLayersAndSetVisibility(map, childs, layerParentID);
        if (descendants.length) {
          isParentFound = true;
          parentLayers.push(...descendants);
        }
      } else {
        childs.forEach(childLayer => {
          const { id: childLayerId } = childLayer;
          const foundParentLayer = parentLayers.find(parentLayer => parentLayer.id === childLayerId);

          if (foundParentLayer) {
            descendantsVisibility.push(isLayerVisible(foundParentLayer.id, map) ? 1 : 0);
          } else {
            descendantsVisibility.push(isLayerVisible(childLayerId, map) ? 1 : 0);
          }
        });
        parentLayers.push({
          id: layerId,
          isVisible: Math.max(...descendantsVisibility) === 1,
        });
      }
    }
  });

  return parentLayers;
};

const LayerTreeView = props => {
  const { className, layers = [], map, subheader, subheaderProps } = props;
  const classes = useStyles(props);

  const allLayers = getRootLayerGroup(map).filter(item => !item.get('isBackground'));
  const layerSelectionStore = getLayerStoreInstance(map.get('mapId'));
  const overlayLayers = layers.filter(item => !item.isBackground);

  const [layerStructure] = useState(() => getLayerStructure(overlayLayers, map.getLayers().getArray()));
  const [selectedLayers, setSelectedLayers] = useState(layerSelectionStore.getLayerSelection().selectedLayers);
  const currentSavedLayers = useRef(selectedLayers);

  /**
   *  EVENT HANDLER
   */

  const handleLayerClick = layer => {
    const layerId = layer.get('mongoDbId');
    const isVisible = isLayerVisible(layerId, map);
    const newSelectedLayers = [];

    if (isLayerInRoot(allLayers, layerId)) {
      const nestedLayerIDs = getNestedIds(layer);
      nestedLayerIDs.forEach(id => {
        newSelectedLayers.push({ layerId: id, isVisible: !isVisible });
        setLayerVisible(map, id, !isVisible);
      });
    } else {
      const layerParentID = layer.get('parentId');
      if (layer instanceof Group) {
        const nestedLayerIDs = getNestedIds(layer);
        nestedLayerIDs.forEach(id => {
          newSelectedLayers.push({ layerId: id, isVisible: !isVisible });
          setLayerVisible(map, id, !isVisible);
        });
      } else {
        newSelectedLayers.push({ layerId, isVisible: !isVisible });
        setLayerVisible(map, layerId, !isVisible);
      }
      const foundParentLayers = searchForParentLayersAndSetVisibility(map, layerStructure, layerParentID);
      foundParentLayers.forEach(element => {
        newSelectedLayers.push({ layerId: element.id, isVisible: element.isVisible });
        setLayerVisible(map, element.id, element.isVisible);
      });
    }
    const mergedSelectedLayers = selectedLayers.map(selectedLayer => {
      return (
        findLayerInArray(newSelectedLayers, selectedLayer.layerId) ||
        findLayerInArray(currentSavedLayers.current, selectedLayer.layerId)
      );
    });
    setSelectedLayers(mergedSelectedLayers);
  };

  const getState = layer => {
    /**
     * state 0: checkbox is not checked
     * state 1: checkbox is checked
     * state -1: is only for groups ->  checkbox is indeterminate
     * stateArray is pushed with the states of their childs
     * the math max/min operations returns the max/min state of all their childs
     * a.e. [0,1,0,1,0,0,0] means, that not all layer must be visible --> state is -1
     * a.e. [0,0,0,0,0,0,0] means, that all layers are not visible --> state is 0
     * a.e. [1,1,1,1,1,1,1] means, that all layers are visible --> state is 1
     */
    const stateArray = [];
    const layerId = layer.get('mongoDbId');

    if (layer instanceof Group) {
      const children = layer.getLayers().array_;
      children.forEach(child => {
        if (Math.min(...stateArray) === -1 || Math.min(...stateArray) === 1) {
          setLayerVisible(map, layerId, true);
        }
        stateArray.push(getState(child));
      });

      const minOfStateArray = Math.min(...stateArray);
      const maxOfStateArray = Math.max(...stateArray);

      return minOfStateArray !== maxOfStateArray ? -1 : minOfStateArray;
    } else {
      stateArray.push(layer.getVisible() ? 1 : 0);
      return layer.getVisible() ? 1 : 0;
    }
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (!deepEqual(currentSavedLayers.current, selectedLayers)) {
      currentSavedLayers.current = selectedLayers;
      layerSelectionStore.saveLayerSelection({ selectedLayers });
    }
  }, [layerSelectionStore, selectedLayers]);

  return (
    <Paper className={classNames(classes.root, className)} square>
      {subheader && (
        <ListSubheader className={classes.subheader} {...subheaderProps}>
          {subheader}
        </ListSubheader>
      )}
      <div className={classes.content}>
        <List className={classes.list} disablePadding>
          {layerStructure.map(({ id, olLayer: layer, childs, groupName, selectAll }) => {
            if (layer instanceof Group) {
              return (
                <GroupListItem
                  key={id}
                  level={0}
                  layer={layer}
                  childs={childs}
                  map={map}
                  name={groupName}
                  openGroups={overlayLayers.length < 10}
                  onHandleLayerClick={handleLayerClick}
                  isState={getState(layer)}
                  getState={getState}
                  selectAll={selectAll}
                />
              );
            } else {
              return (
                <LayerListItem
                  key={id}
                  level={0}
                  layer={layer}
                  map={map}
                  onHandleLayerClick={handleLayerClick}
                  isState={getState(layer)}
                />
              );
            }
          })}
        </List>
      </div>
    </Paper>
  );
};

LayerTreeView.propTypes = {
  className: PropTypes.string,
  layers: PropTypes.array,
  map: PropTypes.object.isRequired,
  subheader: PropTypes.node,
  subheaderProps: PropTypes.object,
};

export default LayerTreeView;
