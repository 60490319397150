/**
 * Delegates a task to a candidate for the current task.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Ajv from 'ajv';
import { useSnackbar } from 'notistack';

import TextField from '@material-ui/core/TextField';

import { Trigger } from '@geomagic/geonam';
import { QueryCandidates } from '@geomagic/geonam-graphql';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';

import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import SingleSelect from '@geomagic/nam-react-core/components/SingleSelect';

const ajv = new Ajv({ allErrors: true });

/**
 * Get JSON schema for validation
 * @returns {Object}
 */

const getSchema = () => ({
  type: 'object',
  required: ['user'],
  properties: {
    user: {
      type: 'object',
    },
    message: {
      type: 'string',
    },
  },
});

const INIT_FORM_STATE = {
  user: null,
  message: '',
};

const DelegateTaskDialog = props => {
  const { formId, isLoading, isMobile, onClose, onSubmit, open, taskId, taskName, user } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState(INIT_FORM_STATE);
  const [openAutocompleteUser, setOpenAutocompleteUser] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);

  const schema = getSchema();

  /**
   *  QUERIES
   */

  const { data: dataCandidates, loading: isLoadingUsers } = useQuery(QueryCandidates, {
    variables: {
      taskIds: [taskId],
    },
  });

  const candidates = dataCandidates?.candidates?.filter(candidate => candidate.id !== user.id);

  /**
   *  EVENT HANDLER
   */

  const handleCloseAutoCompleteUser = () => {
    setOpenAutocompleteUser(false);
  };

  const handleChangeForm = key => (event, value) => {
    event.persist();

    setForm(prev => ({ ...prev, [key]: key === 'message' ? event.target.value : value }));
  };

  const handleOpenAutoCompleteUser = () => {
    setOpenAutocompleteUser(true);
  };

  const getErrorByKey = key => {
    if (validationErrors) {
      const hasError = validationErrors.find(({ dataPath }) => dataPath.endsWith(key));

      return !!hasError;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validation = ajv.compile(schema);
    const isValid = validation(form);

    if (isValid) {
      onSubmit(form);
    } else {
      setValidationErrors(validation.errors);
    }
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (!open) {
      setForm(INIT_FORM_STATE);
    }
  }, [open]);

  useEffect(() => {
    if (candidates && form.user) {
      const userExists = candidates.find(({ id }) => id === form.user.id);

      if (!userExists) {
        setForm(prev => ({ ...prev, user: null }));

        enqueueSnackbar(i18n.t('process.notification.userResetted'), {
          key: 'userResetted',
          preventDuplicate: true,
          variant: 'info',
        });
      }
    }
  }, [candidates, enqueueSnackbar, form.user]);

  /**
   *  COMPONENTS
   */

  const ContentComponent = (
    <form id={formId} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <SingleSelect
        disableClearable={false}
        error={getErrorByKey('user')}
        getOptionLabel={item => item.fullName}
        getOptionSelected={(option, item) => option === item}
        label={i18n.t('label.user')}
        loading={isLoadingUsers}
        onChange={handleChangeForm('user')}
        options={candidates}
        required={schema.required.includes('user')}
        value={form?.user}
        open={openAutocompleteUser}
        onOpen={handleOpenAutoCompleteUser}
        onClose={handleCloseAutoCompleteUser}
      />
      <TextField
        fullWidth
        label={i18n.t('label.message')}
        multiline
        onChange={handleChangeForm('message')}
        rows={3}
        value={form?.message}
        {...DEFAULT_TEXT_FIELD_PROPS}
      />
    </form>
  );

  const ActionsComponent = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={onClose}>
        {i18n.t('button.cancel')}
      </Trigger>

      <Trigger
        {...PRIMARY_TRIGGER_PROPS}
        onClick={handleSubmit}
        isLoading={isLoadingUsers || isLoading}
        isDisabled={!form.user || isLoading || isLoadingUsers}
        tooltip={form.user ? null : i18n.t('tooltip.noUserSelected')}
      >
        {i18n.t('button.delegate')}
      </Trigger>
    </>
  );

  return (
    <StackedDialog
      actions={ActionsComponent}
      content={ContentComponent}
      isFullscreen={isMobile}
      handleClose={onClose}
      open={open}
      title={i18n.t('process.dialog.delegateTask.title', { variables: { taskName } })}
      withPadding
    />
  );
};

DelegateTaskDialog.propTypes = {
  formId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  taskId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default DelegateTaskDialog;
