export const FORM_COMPONENT_CHILDREN_KEY = 'children';
export const FORM_COMPONENT_MIN_HEIGHT = 40;
export const FORM_COMPONENT_TOGGLE_BUTTON_WIDTH = 150;

export const PROPERTY_NAME_DUEDATE = 'property.dueDate';

export const MARKDOWN_IDENTIFIER = 'MARKDOWN';
export const PLAIN_IDENTIFIER = 'PLAIN';

export const ENUM_SELECT_COMPONENT = 'Select';

export const FIELD_DATA_COMPONENT = 'Data';
export const FIELD_TEXT_COMPONENT = 'Text';

export const FORM_ELEMENT_DOCUMENTS = 'FormElementDocuments';
export const FORM_ELEMENT_ENUM = 'FormElementEnum';
export const FORM_ELEMENT_FIELD = 'FormElementField';
export const FORM_ELEMENT_PICTURES = 'FormElementPictures';
