/**
 * Add geometry type to feature properties
 * @param {Array.<Object>} features - Array of OpenLayers features
 * @param {Array.<Object>} typesByStyleId - FunclocGeomTypeCat
 * @returns {void}
 */

const addGeometryTypeToFeatures = (features = [], typesByStyleId = []) => {
  const extendedFeatures = features.map(feature => {
    const { geometryStyleId } = feature;
    const newGeometryType = typesByStyleId.find(type => type.styleId === geometryStyleId);

    return {
      ...feature,
      properties: { ...feature.properties, geometryType: newGeometryType },
    };
  });

  return extendedFeatures;
};

export default addGeometryTypeToFeatures;
