import flattenDeepByKey from '@utils/flattenDeepByKey';
import { FORM_COMPONENT_CHILDREN_KEY } from '../consts';

const isDefectStatusSelected = (id, elements) => {
  const parent = elements.find(({ children }) => children?.some(({ id: id2 }) => id2 === id));
  return parent.children.some(({ statusValue }) => statusValue?.isDefectStatus);
};

export const getMandatoryElements = (forms = []) => {
  const children = flattenDeepByKey(forms, FORM_COMPONENT_CHILDREN_KEY);
  const mandatoryElements = children.filter(
    ({ displayedIfDefect, id, mandatory }) => mandatory && (!displayedIfDefect || isDefectStatusSelected(id, children))
  );
  return mandatoryElements;
};

export default getMandatoryElements;
