import React, { Fragment, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';

import DefaultAppBar from '@material-ui/core/AppBar';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { getReference } from '@geomagic/geonam';
import { ContentRoot } from '@geomagic/layout';

import EntityForm from '@geomagic/nam-react-core/components/EntityForm';
import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles(({ palette, spacing }) => ({
  bottomToolbar: {
    background: palette.background.default,
    borderTop: `1px solid ${palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(),
    paddingTop: spacing(),
  },
}));

const DispatchForm = props => {
  const {
    CloseComponent,
    doc,
    draftRef,
    entityClasses,
    entityTypes,
    isMobile,
    isReadOnly,
    onChange,
    step,
    triggerProps,
  } = props;

  const entity = doc.getPatchedEntity();
  const { attributeValues, className, entityType, featureCollections } = entity;

  const features = featureCollections[0]?.features;
  const hasFeatures = features?.length > 0;
  const isDraft = !!doc?.draft;
  const isTypeDisabled = hasFeatures || !isDraft || isReadOnly;
  const formId = step?.id;
  const filteredEntityTypes = isDraft ? entityTypes.filter(({ creatable }) => creatable) : entityTypes;
  const initialEntityTypeId = entityType?.id || filteredEntityTypes[0]?.id;

  const formContextRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [expandedGroups, setExpandedGroups] = useState({});
  const [entityTypeId, setEntityTypeId] = useState(initialEntityTypeId);
  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleChange = value => {
    const entityType = entityTypes.find(entityType => entityType.id === entityTypeId);
    const entityTypeReference = getReference(entityType);

    const newPatch = isDraft
      ? [
          {
            op: 'replace',
            path: `/entityType`,
            value: entityTypeReference,
          },
          {
            op: 'replace',
            path: `/attributeValues`,
            value,
          },
        ]
      : {
          op: 'replace',
          path: `/attributeValues`,
          value,
        };

    onChange(newPatch).then(() => {
      enqueueSnackbar(i18n.t('dispatch.notification.savedFormData'), {
        key: 'savedValues',
        preventDuplicate: true,
        variant: 'success',
      });
    });
  };

  const handleChangeSelect = event => {
    const id = Number(event.target.value);

    setEntityTypeId(id);
    formContextRef.current.replaceValues({});
  };

  const handleValidate = callback => {
    return new Promise((resolve, reject) => {
      const { submit, validate, previousValues } = formContextRef.current;
      const isValid = validate();

      if (isValid) {
        submit().then(values => {
          const previous = { entityTypeId: initialEntityTypeId, attributeValues: previousValues };
          const current = { entityTypeId, attributeValues: values };
          const areValuesEqual = deepEqual(previous, current);

          areValuesEqual ? callback && callback() : handleChange(values);

          resolve();
        });
      } else {
        reject();
      }
    });
  };

  const notifyValuesEqual = () => {
    enqueueSnackbar(i18n.t('dispatch.notification.valuesEqual'), {
      key: 'valuesEqual',
      preventDuplicate: true,
      variant: 'info',
    });
  };

  /**
   *  FORM CONTEXT
   */

  useImperativeHandle(draftRef, () => ({ onValidateForm: handleValidate }));

  /**
   *  FORM PROPS
   */

  const formProps = isDraft ? { defaultValues: attributeValues, entityClassName: className, entityTypeId } : { entity };

  return (
    <Fragment>
      <ContentRoot scrollable withCustomScrollbar={!isMobile}>
        <EntityForm
          entityClasses={entityClasses}
          expandedGroups={expandedGroups}
          formId={formId}
          isReadOnly={isReadOnly}
          isSubmitOnEnter={false}
          setExpandedGroups={setExpandedGroups}
          {...formProps}
        >
          {(fields, formContext) => {
            formContextRef.current = formContext;
            return (
              <>
                <Select
                  {...DEFAULT_TEXT_FIELD_PROPS}
                  autoWidth
                  disabled={isTypeDisabled}
                  fullWidth
                  margin="dense"
                  native
                  onChange={handleChangeSelect}
                  value={entityTypeId}
                >
                  {filteredEntityTypes.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Select>
                {fields}
              </>
            );
          }}
        </EntityForm>
      </ContentRoot>
      {!isReadOnly && (
        <DefaultAppBar position="static" color="inherit">
          <Toolbar className={classes.bottomToolbar}>
            <Trigger {...PRIMARY_TRIGGER_PROPS} {...triggerProps} onClick={() => handleValidate(notifyValuesEqual)}>
              {i18n.t('button.save')}
            </Trigger>
            {CloseComponent}
          </Toolbar>
        </DefaultAppBar>
      )}
    </Fragment>
  );
};

DispatchForm.propTypes = {
  doc: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  entityTypes: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.object.isRequired,
  triggerProps: PropTypes.object,
};

export default DispatchForm;
