import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Provider as DatabaseProvider } from 'rxdb-hooks';

import CloseIcon from '@material-ui/icons/Close';

import { Trigger } from '@geomagic/core';
import { Geonam } from '@geomagic/geonam';

import initializeDatabase from '@database/initialize';
import ServiceWorkerProvider from '@serviceWorker/ServiceWorkerProvider';
import databaseCollections from '@synchronization/databaseCollections';
import themeOverrides from '@theme/overrides';
import { dark, light } from '@theme/palette';
import { TeleporterProvider } from '@utils/useTeleport';

import App from './App';
import { DEFAULT_SNACKBAR_PROPS } from './consts';
import { STATE, STATE_SAVE_KEYS } from './state';

import './index.css';

const useStyles = makeStyles(() => ({
  snackbar: {
    flex: 1,
    flexWrap: 'nowrap',
  },
  snackbarMessage: {
    flex: 1,
  },
}));

const CloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return snackbarKey !== '_loading_' ? (
    <Trigger
      color="inherit"
      icon={<CloseIcon />}
      onClick={() => closeSnackbar(snackbarKey)}
      size="small"
      variant="icon"
    />
  ) : null;
};

const Root = () => {
  const databaseRef = useRef();
  const [isInitialized, setInitialized] = useState(false);
  const classes = useStyles();

  const theme = {
    palette: { ...(useMediaQuery('(prefers-color-scheme: dark)') ? dark : light) },
    props: themeOverrides,
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const initialize = async () => {
      const _database = await initializeDatabase(databaseCollections);
      databaseRef.current = _database;
      setInitialized(true);
    };

    initialize();
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <ServiceWorkerProvider>
      <DatabaseProvider db={databaseRef.current}>
        <TeleporterProvider>
          <SnackbarProvider
            {...DEFAULT_SNACKBAR_PROPS}
            action={snackbarKey => <CloseButton snackbarKey={snackbarKey} />}
            classes={{ root: classes.snackbar, message: classes.snackbarMessage }}
            hideIconVariant
            maxSnack={10}
          >
            <Geonam
              defaultGlobalState={{ ...STATE, vectorTileServerUrl: window.VT_SERVER_URL }}
              keysToSave={STATE_SAVE_KEYS}
              isSaveState
              theme={theme}
            >
              <App />
            </Geonam>
          </SnackbarProvider>
        </TeleporterProvider>
      </DatabaseProvider>
    </ServiceWorkerProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
