const statusColors = {
  CLOSED: '#49ad34',
  CRITICAL: '#d10a11',
  INACTIVE: '#dadada',
  IN_PROGRESS: '#fdd22c',
  OPEN: '#98c4cc',
};

const officeColors = {
  excel: '#217346',
  office: '#d83b01',
  powerPoint: '#b7472a',
  word: '#2b579a',
};

const secondary = {
  50: '#f7f9e0',
  100: '#ebf1b3',
  200: '#dee780',
  300: '#d0dd4d',
  400: '#c6d626',
  500: '#bccf00',
  600: '#b6ca00',
  700: '#adc300',
  800: '#a5bd00',
  900: '#97b200',
  light: '#d0dd4d',
  main: '#bccf00',
  dark: '#adc300',
};

const light = {
  primary: {
    50: '#e0e9ed',
    100: '#b3c7d2',
    200: '#80a2b5',
    300: '#4d7c97',
    400: '#266080',
    500: '#00446a',
    600: '#003e62',
    700: '#003557',
    800: '#002d4d',
    900: '#001f3c',
    light: '#406f98',
    main: '#00446a',
    dark: '#001e3f',
  },
  secondary,
  office: officeColors,
  status: statusColors,
};

const dark = {
  type: 'dark',
  primary: {
    50: '#e9eff3',
    100: '#c9d8e1',
    200: '#a5becd',
    300: '#80a4b9',
    400: '#6591aa',
    500: '#4a7d9b',
    600: '#437593',
    700: '#3a6a89',
    800: '#32607f',
    900: '#224d6d',
    light: '#80a4b9',
    main: '#4a7d9b',
    dark: '#3a6a89',
  },
  secondary,
  office: officeColors,
  status: statusColors,
};

export { dark, light, statusColors };
