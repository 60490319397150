import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Trigger } from '@geomagic/core';
import { Markdown } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';

import { PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles(({ breakpoints, palette, spacing, typography }) => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginRight: spacing(),
  },
  dialogContent: {
    display: 'flex',
    height: '100vh',
    paddingTop: 0,
    [breakpoints.down('md')]: {
      padding: spacing(),
      paddingRight: 0,
    },
  },
  content: {
    display: 'flex',
  },
  trigger: {
    marginLeft: spacing(),
    minWidth: 'auto',
  },
  markdownTrigger: {},
  dialog: {},
  dialogTitle: {},
  dialogActions: {},
}));

const MarkDownTrigger = props => {
  const classes = useStyles(props);
  const { className, isMobile, onSubmit, open, source, title, ...triggerProps } = props;
  const [isDialogOpen, setDialogOpen] = useState(open);

  React.useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  const handleCloseDialog = event => {
    event.stopPropagation();
    setDialogOpen(false);
  };

  const handleOpenDialog = event => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const closeTrigger = (
    <Trigger className={classes.trigger} onClick={handleCloseDialog} {...PRIMARY_TRIGGER_PROPS}>
      {i18n.t('button.close')}
    </Trigger>
  );

  const actions = !isMobile ? <DialogActions className={classes.dialogActions}>{closeTrigger}</DialogActions> : null;

  const header = isMobile ? (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        {closeTrigger}
      </Toolbar>
    </AppBar>
  ) : (
    <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
  );

  const dialogProps = isMobile ? { fullScreen: true } : { fullWidth: true, maxWidth: 'md' };

  return (
    <Fragment>
      <Trigger
        className={classNames(classes.markdownTrigger, className)}
        onClick={handleOpenDialog}
        {...triggerProps}
      />
      <Dialog
        className={classes.dialog}
        onClick={event => event.stopPropagation()}
        onClose={handleCloseDialog}
        open={!!isDialogOpen}
        {...dialogProps}
      >
        {header}
        <DialogContent className={classes.dialogContent}>
          <ContentRoot className={classes.content} scrollable withPadding={false} withCustomScrollbar={!isMobile}>
            <Markdown source={source} />
          </ContentRoot>
        </DialogContent>
        {actions}
      </Dialog>
    </Fragment>
  );
};

MarkDownTrigger.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  source: PropTypes.string,
  title: PropTypes.string,
};

export default MarkDownTrigger;
