const STATE_SAVE_KEYS = ['lastFetch', 'loginStatus', 'preferences', 'user'];

const STATE_LOGGED_OUT = {
  loginStatus: null,
  user: null,
};

const STATE = {
  ...STATE_LOGGED_OUT,
  lastFetch: null,
  preferences: {
    isMapVisible: true,
  },
};

export { STATE, STATE_LOGGED_OUT, STATE_SAVE_KEYS };
