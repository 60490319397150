import React, { memo } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { makeStyles } from '@material-ui/styles';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Trigger } from '@geomagic/core';
import { getEntityType } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';

import getFeatures from './getFeatures';
import getTasksAmount from '../Tasks/getTasksAmount';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
  },
  trigger: {
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const DispatchListActions = props => {
  const {
    areMapActionsDisabled,
    data,
    entityClasses,
    onCloseSwipeableArea,
    onOpenChecklist,
    onOpenJournal,
    onOpenTasks,
    onRemoveDraft,
    onShowOnMap,
  } = props;

  const entity = data.getPatchedEntity();

  const features = !areMapActionsDisabled && getFeatures([data], entityClasses);
  const isDraft = !!data?.draft;

  const entityType = getEntityType(entityClasses, entity?.className, entity?.entityType?.id);
  const hasFeatures = features?.length > 0;
  const label = data?.draft ? entityType?.name : entity?.displayName;

  const classes = useStyles();

  const toggleActionDrawer = useToggleActionDrawer();

  /**
   *  EVENT HANDLER
   */

  const handleOpenDrawer = event => {
    event.stopPropagation();

    toggleActionDrawer({
      title: label,
      items: menuItems,
    });
  };

  const handleOpenChecklist = event => {
    event.stopPropagation();
    onOpenChecklist && onOpenChecklist(data);
  };

  const handleOpenJournal = event => {
    event.stopPropagation();
    onOpenJournal && onOpenJournal(data);
  };

  const handleOpenTasks = event => {
    event.stopPropagation();
    onOpenTasks && onOpenTasks(data);
  };

  const handleRemoveDraft = event => {
    event.stopPropagation();
    onRemoveDraft && onRemoveDraft(label, data);
  };

  const handleShowOnMap = event => {
    event.stopPropagation();
    onCloseSwipeableArea && onCloseSwipeableArea();
    onShowOnMap && onShowOnMap(features, entity.id);
  };

  /**
   *  MENU ITEMS
   */

  const getMenuItems = () => {
    const items = [];

    if (!areMapActionsDisabled) {
      items.push({
        disabled: !hasFeatures,
        id: 'showOnMap',
        label: i18n.t('label.showOnMap'),
        onClick: handleShowOnMap,
      });
    }

    items.push({
      id: 'checklist',
      label: i18n.t('checklist.label.menuItem', {
        variables: { amount: entity?.checklistItems?.length || '0' },
      }),
      onClick: handleOpenChecklist,
    });

    items.push({
      id: 'journal',
      label: i18n.t('journal.label', {
        variables: { amount: entity?.journal?.length || '0' },
      }),
      onClick: handleOpenJournal,
    });

    items.push({
      id: 'tasks',
      label: i18n.t('process.label.menuItem', {
        variables: { amount: getTasksAmount(entity?.processInstances) || '0' },
      }),
      onClick: handleOpenTasks,
    });

    if (isDraft) {
      items.push({
        color: 'secondary',
        id: 'removeDraft',
        label: i18n.t('dispatch.label.removeDraft'),
        onClick: handleRemoveDraft,
      });
    }

    return items;
  };

  const menuItems = getMenuItems();

  return (
    <div className={classes.root}>
      {menuItems.length > 0 && (
        <Trigger
          {...DEFAULT_TRIGGER_ICON_PROPS}
          className={classes.trigger}
          icon={<MoreVertIcon />}
          onClick={handleOpenDrawer}
        />
      )}
    </div>
  );
};

DispatchListActions.propTypes = {
  areMapActionsDisabled: PropTypes.bool,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  onCloseSwipeableArea: PropTypes.func,
  onOpenChecklist: PropTypes.func,
  onOpenJournal: PropTypes.func,
  onOpenTasks: PropTypes.func,
  onRemoveDraft: PropTypes.func,
  onShowOnMap: PropTypes.func,
};

export default memo(DispatchListActions, (prev, next) => deepEqual(prev, next));
