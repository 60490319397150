import getMandatoryElements from './getMandatoryElements';
import isCompletedFormElement from './isCompletedFormElement';

/**
 *  A function to check whether given forms filled out all mandatory fields.
 *
 * @param {array} forms
 * @returns {boolean}
 */

const checkFormIsFinished = forms => {
  const mandatoryElements = getMandatoryElements(forms);

  return mandatoryElements.filter(isCompletedFormElement).length === mandatoryElements.length;
};

export default checkFormIsFinished;
