import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { DelayedTooltip } from '@geomagic/geonam';

import getEntityStateByProcessingStatus from '../utils/getEntityStateByProcessingStatus';

const useStyles = makeStyles(({ mixins, palette, shape, spacing, typography, zIndex }) => {
  return {
    root: {
      display: 'flex',
    },
    badge: {
      borderRadius: shape.borderRadius,
      display: 'flex',
      margin: spacing(0, 0.25),
      padding: spacing(0.5),
      whiteSpace: 'nowrap',
      '&$small': {
        padding: spacing(0.25),
      },
      '&$large': {
        padding: spacing(),
      },
    },
    label: {
      fontSize: typography.pxToRem(13),
      fontWeight: 'bold',
      padding: spacing(0, 0.5),
      textTransform: 'uppercase',
      '&$small': {
        fontSize: typography.pxToRem(12),
        padding: spacing(0, 0.25),
      },
      '&$large': {
        fontSize: typography.pxToRem(14),
        padding: spacing(0, 1),
      },
    },
    circle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: 26,
      height: 26,
      '&$extraSmall': {
        width: 14,
        height: 14,
      },
      '&$small': {
        width: 18,
        height: 18,
      },
      '&$large': {
        width: 34,
        height: 34,
      },
    },
    border: {
      border: `2px solid ${palette.background.paper}`,
    },
    extraSmall: {},
    small: {},
    large: {},
  };
});

const EntityState = props => {
  const classes = useStyles(props);
  const theme = useTheme();

  const { className, entity, size, variant } = props;
  const { criticalStatusMessage, processingStatus = [] } = entity;

  /**
   * COMPONENTS
   */

  const getBadgeComponent = () => {
    return processingStatus.map(status => {
      const { style, title } = getEntityStateByProcessingStatus({
        criticalStatusMessage,
        status,
        theme,
      });

      return (
        <div
          key={status}
          className={classNames(classes.badge, classes[size], { [classes.border]: !!style })}
          style={style}
        >
          <Typography className={classNames(classes.label, classes[size])}>{title}</Typography>
        </div>
      );
    });
  };

  const getCircleComponent = () => {
    const { style, title } = getEntityStateByProcessingStatus({
      criticalStatusMessage,
      status: processingStatus?.[0],
      theme,
    });

    return title ? (
      <DelayedTooltip title={title}>
        <div className={classNames(classes.circle, classes[size], { [classes.border]: !!style })} style={style} />
      </DelayedTooltip>
    ) : (
      <div className={classNames(classes.circle, classes[size], { [classes.border]: !!style })} style={style} />
    );
  };

  return (
    <div className={classNames(classes.root, className)}>
      {variant === 'badge' ? getBadgeComponent() : getCircleComponent()}
    </div>
  );
};

EntityState.defaultProps = {
  size: 'medium',
  variant: 'badge',
};

EntityState.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  variant: PropTypes.oneOf(['badge', 'circle']),
};

export default EntityState;
