import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getPointResolution } from 'ol/proj';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      root: {
        display: 'flex',
        pointerEvents: 'auto',
        backgroundColor: 'transparent',
      },
      scale: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 96,
        height: 29,
        border: '1px solid white',
        textAlign: 'center',
        color: 'white',
        textShadow: '0px 0px 5px black,0px 0px 3px black,0px 0px 1px black',
        borderTop: 'none',
        backgroundColor: 'rgba(255,255,255,0.25)',
        fontSize: '0.8em',
        '&:before': {
          position: 'absolute; display: block; content: ""',
          border: '1px solid black',
          borderTop: 'none',
          width: 96,
          height: 29,
          boxSizing: 'border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box',
        },
      },
      typography: {
        color: palette.common.white,
      },
    }),
  { name: 'Scale' }
);

const width = (projection, resolution, center) => {
  let unit = 'm';
  let w = 0;
  if (projection && resolution && center) {
    w = getPointResolution(projection, resolution, center) * 100;
    if (w > 1000) {
      w /= 1000;
      unit = 'km';
    }
    return { width: w.toFixed(1), unit };
  }
};

const Scale = props => {
  const classes = useStyles(props);
  const { className, controlProps, map } = props;

  const projection = map.getView().getProjection();
  const resolution = map.getView().getResolution();
  const center = map.getView().getCenter();
  const calculated = width(projection, resolution, center);
  return (
    <Paper className={classNames(classes.root, className)} elevation={0} square {...controlProps}>
      <div className={classes.scale}>
        <Typography variant="caption" className={classes.typography}>
          {calculated && calculated.width} {calculated && calculated.unit}
        </Typography>
      </div>
    </Paper>
  );
};

Scale.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  controlProps: PropTypes.object,
  map: PropTypes.object.isRequired,
};

export default Scale;
