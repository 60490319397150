import pointer from 'json-pointer';
import { MutationAddComment } from '@geomagic/geonam-graphql';

import getDiffs from '../getDiffs';

const updateJournal = async ({ client, entity, entityReference, patchedEntity, path }) => {
  const journal = pointer.get(entity, path);
  const patchedJournal = pointer.get(patchedEntity, path);

  const { added } = getDiffs(journal, patchedJournal);

  added.sort((a, b) => b.order - a.order);

  if (added.length > 0) {
    for (let i = 0; i < added.length; i++) {
      const { message } = added[i];
      await client.mutate({
        mutation: MutationAddComment,
        variables: { sourceEntity: { ...entityReference }, message },
      });
    }
  }
};

export default updateJournal;
