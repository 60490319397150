import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';

import { FileUpload } from '@geomagic/files';
import { getEntityClass } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import { CLASSNAME_DOCUMENT } from '@graphql/consts';
import getCompressedHashedFile from '@image/getCompressedHashedFile';
import getHash from '@image/getHash';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

import DocumentFormDialog from './DocumentFormDialog';

const useStyles = makeStyles(({ palette, shape, spacing, transitions }) => ({
  root: {},
}));

const IMAGE_TYPE_START = 'image/';
const checkDefaultCompression = type => type?.startsWith(IMAGE_TYPE_START);

const DocumentUpload = props => {
  const {
    accept = ['image/*'],
    checkCompression = checkDefaultCompression,
    className,
    doc,
    documents = [],
    entityClasses,
    icon,
    isMobile,
    label,
    onChange,
    triggerProps,
    withAttributesForm = false,
  } = props;

  const entityClass = getEntityClass(entityClasses, CLASSNAME_DOCUMENT);
  const entityTypes = entityClass?.entityTypes;

  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const [fileProps, setFileProps] = useState(null);
  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleAdd = async event => {
    const file = event.target.files[0];
    const compression = checkCompression && (await checkCompression(file.type));

    try {
      if (compression) {
        const { hash, file: compressedFile } = await getCompressedHashedFile(file);
        setFileProps({ file: compressedFile, hash, name: file.name, type: file.type });
      } else {
        const hash = await getHash(file);
        setFileProps({ file, hash, name: file.name, type: file.type });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setFileProps(null);
  };

  const handleSubmit = (attributeValues, entityType) => {
    const { name } = fileProps;
    const isFileNameExisiting = documents.some(document => document.displayName.toLowerCase() === name.toLowerCase());

    if (isFileNameExisiting) {
      enqueueSnackbar(i18n.t('notification.fileNameAlreadyExisists'), {
        key: 'imageAlreadyExisists',
        preventDuplicate: true,
        variant: 'info',
      });
    } else {
      handleUpdate({ attributeValues, entityType, ...fileProps });
    }
  };

  const handleUpdate = async ({ attributeValues, entityType, hash, file, name, type }) => {
    const newDocuments = [
      ...documents,
      {
        className: CLASSNAME_DOCUMENT,
        displayName: name,
        properties: { contentType: type, hash },
        ...(attributeValues && entityType && { attributeValues, entityType }),
      },
    ];

    fileProps && setFileProps(null);

    const execute = async () => {
      await doc.putAttachment({ id: hash, data: file, type });
      await onChange(newDocuments);
    };

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('notification.uploadDocument'),
      finishedText: i18n.t('notification.uploadedDocument'),
      finishedVariant: 'success',
      func: execute,
    });
  };

  return (
    <Fragment>
      <FileUpload
        accept={isArray(accept) ? accept.join(',') : accept}
        className={classNames(classes.root, className)}
        color="primary"
        icon={icon}
        multiple={false}
        onChange={handleAdd}
        {...PRIMARY_TRIGGER_PROPS}
        {...triggerProps}
      >
        {label}
      </FileUpload>

      <DocumentFormDialog
        className={CLASSNAME_DOCUMENT}
        fileProps={fileProps}
        entityClasses={entityClasses}
        entityTypes={entityTypes}
        isFullScreen={isMobile}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={!!fileProps}
        setFileProps={setFileProps}
        withAttributesForm={withAttributesForm}
      />
    </Fragment>
  );
};

DocumentUpload.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  checkCompression: PropTypes.func,
  className: PropTypes.string,
  doc: PropTypes.object.isRequired,
  documents: PropTypes.array,
  entityClasses: PropTypes.array.isRequired,
  icon: PropTypes.node,
  isMobile: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
  withAttributesForm: PropTypes.bool,
};

export default DocumentUpload;
