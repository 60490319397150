import { useQuery } from '@apollo/client';

import { VIEW_CODE } from '@components/Map/consts';
import QueryEntityClasses from '@graphql/queries/QueryEntityClasses';
import QuerySSOSettings from '@graphql/queries/QuerySSOSettings';
import QuerySystemInformation from '@graphql/queries/QuerySystemInformation';
import QueryWorld from '@graphql/queries/QueryWorld';

const useGeneralQueries = ({ appProps, isOnline, user }) => {
  /**
   *  QUERY CONFIG
   */

  const queryConfigEntityClasses = {
    skip: isOnline && !user,
  };

  const queryConfigSSOSettings = {
    fetchPolicy: 'network-only',
  };

  const queryConfigSystemInformation = {
    skip: isOnline && !user,
  };

  const queryConfigWorld = {
    skip: isOnline && !user,
    variables: {
      viewCodes: [appProps?.viewCode || VIEW_CODE],
    },
  };

  /**
   *  QUERIES
   */

  const { data: dataEntityClasses, loading: loadingEntityClasses } = useQuery(
    QueryEntityClasses,
    queryConfigEntityClasses
  );

  const { data: dataSSOSettings, loading: loadingSSOSettings } = useQuery(QuerySSOSettings, queryConfigSSOSettings);

  const { data: dataSystemInformation, loading: loadingSystemInformation } = useQuery(
    QuerySystemInformation,
    queryConfigSystemInformation
  );

  const { data: dataWorld, loading: loadingWorld } = useQuery(QueryWorld, queryConfigWorld);

  return {
    entityClasses: dataEntityClasses?.entityClasses,
    ssoSettings: dataSSOSettings?.systemInformation?.ssoSettings,
    systemInformation: dataSystemInformation?.systemInformation,
    worlds: dataWorld?.worlds,
    loadingEntityClasses,
    loadingSSOSettings,
    loadingSystemInformation,
    loadingWorld,
  };
};

export default useGeneralQueries;
