import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Trigger } from '@geomagic/core';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {},
}));

const StepperButton = props => {
  const classes = useStyles(props);
  const { className, color, text, icon, iconLast, disabled, onClick, isMobile } = props;

  const defaultProps = {
    color,
    disabled,
    onClick,
  };

  return isMobile ? (
    <Trigger
      className={classNames(classes.root, className)}
      {...defaultProps}
      variant="icon"
      icon={React.cloneElement(icon, { style: { fontSize: 30 } })}
    />
  ) : (
    <Trigger className={classNames(classes.root, className)} {...defaultProps} variant="text">
      {iconLast ? (
        <Fragment>
          {text}
          {icon}
        </Fragment>
      ) : (
        <Fragment>
          {icon}
          {text}
        </Fragment>
      )}
    </Trigger>
  );
};

StepperButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  iconLast: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default StepperButton;
