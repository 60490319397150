import pointer from 'json-pointer';
import { getAttributeTypesByClassAndType, getReference } from '@geomagic/geonam';
import { MutationDeleteDocuments } from '@geomagic/geonam-graphql';

import { CLASSNAME_DOCUMENT } from '@graphql/consts';
import MutationUploadDocument from '@graphql/mutations/MutationUploadDocument';

import getDiffs from '../getDiffs';
import getFilePropsFromDocumentEntity from '../getFilePropsFromDocumentEntity';

const UPDATE_KEY = 'id';

const transformer = ({ attributeType, value }) => ({
  attributeTypeId: attributeType.id,
  value,
});

const getAdditionalMetaData = (item, entityClasses) => {
  let props = { attributeValues: [] };

  if (item?.entityType && item.attributeValues) {
    const entityTypeId = item?.entityType?.id;
    const attributeTypes = getAttributeTypesByClassAndType(entityClasses, CLASSNAME_DOCUMENT, entityTypeId);

    props = {
      documentTypeId: item?.entityType?.id,
      attributeValues: item.attributeValues.map(transformer).filter(({ attributeTypeId }) => {
        const { readOnly } = attributeTypes.find(({ id }) => id === attributeTypeId);
        return !readOnly;
      }),
    };
  }

  return props;
};

const updateDocuments = async ({ client, doc, entityClasses, entityReference, entity, patchedEntity, path }) => {
  const documents = pointer.get(entity, path);
  const patchedDocuments = pointer.get(patchedEntity, path);

  const { added, removed } = getDiffs(documents, patchedDocuments, UPDATE_KEY);

  if (added.length > 0) {
    for (let i = 0; i < added.length; i++) {
      const item = added[i];
      const { hash, name, type } = getFilePropsFromDocumentEntity(item);

      const additionalMetaData = getAdditionalMetaData(item, entityClasses);
      const attachment = await doc.getAttachment(hash);
      const blob = await attachment.getData();
      const file = new File([blob], name, { type });

      await client.mutate({
        mutation: MutationUploadDocument,
        variables: { metadata: { entityReference, ...additionalMetaData }, file },
      });
    }
  }

  if (removed.length > 0) {
    await client.mutate({
      mutation: MutationDeleteDocuments,
      variables: { documents: removed.map(item => getReference(item)) },
    });
  }
};

export default updateDocuments;
