import { WORLD_CODE } from '../consts';

/**
 * Get a world by code
 * @param {Array.<Object>} worlds
 * @param {string|undefined} code
 * @returns {Object|undefined}
 */

const getWorldByCode = (worlds = [], code) => {
  const world = worlds.find(world => world?.code === (code || WORLD_CODE));

  return world;
};

export default getWorldByCode;
