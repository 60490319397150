import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { BackgroundSwitcher, getLayerStoreInstance, MINIMUM_CONFIG } from '@geomagic/map';

import LayerTreeView from '@components/Map/LayerTreeView';
import { transformLayers } from '@components/Map';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
  const isLightTheme = palette.type === 'light';

  return {
    '@global': {
      '.MuiInputBase-root': {
        minHeight: 40,
      },
    },
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    listSubheader: {
      backgroundColor: isLightTheme ? palette.grey[100] : palette.grey.A400,
      borderBottom: `1px solid ${palette.divider}`,
      zIndex: 2,
    },
    content: {
      padding: spacing(),
    },
    backgroundSwitcher: {
      boxShadow: 'none',
      marginBottom: 0,
      padding: 0,
    },
    layerTreeViewRoot: {
      margin: 0,
      boxShadow: 'none',
      width: '100%',
    },
    layerTreeViewContent: {
      padding: 0,
    },
  };
});

const MapPreferences = props => {
  const classes = useStyles(props);
  const { className, isMobile, mapProps } = props;
  const { mapRef, view } = mapProps;
  const layerSelectionStore = getLayerStoreInstance(MINIMUM_CONFIG.mapId);
  const [layers] = useState(() => transformLayers(view, layerSelectionStore));

  const map = mapRef.current.map;

  return (
    <ContentRoot
      className={classNames(classes.root, className)}
      scrollable
      withPadding={false}
      withCustomScrollbar={!isMobile}
    >
      <ListSubheader className={classes.listSubheader}>{i18n.t('map.default.background')}</ListSubheader>
      <div className={classes.content}>
        <BackgroundSwitcher
          variant="select"
          map={map}
          controlProps={{ classes: { root: classes.backgroundSwitcher } }}
        />
      </div>
      <Divider />
      <ListSubheader className={classes.listSubheader}>{i18n.t('map.default.layers')}</ListSubheader>
      <div className={classes.content}>
        <LayerTreeView
          classes={{ root: classes.layerTreeViewRoot, content: classes.layerTreeViewContent }}
          layers={layers}
          map={map}
        />
      </div>
    </ContentRoot>
  );
};

MapPreferences.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
};

export default MapPreferences;
