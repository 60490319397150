/**
 * Get an id from path items by pathname
 * @param {object} params
 * @param {string} params.basePath - Current basePath
 * @param {array.<object>} params.items - All available path items
 * @param {string} params.pathname - Current pathname
 * @returns {string}
 */

const getPathByPathname = params => {
  const { basePath, items, pathname } = params;

  const existingItem = items.find(({ path }) => {
    const _pathname = basePath !== '/' ? pathname.replace(basePath, '') : pathname;
    return _pathname.startsWith(path);
  });

  return existingItem?.id || '';
};

export default getPathByPathname;
