import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';

const getPatch = (patches, jsonPatch) => {
  const clonedPatches = patches ? cloneDeep(patches) : [];
  const patch = isArray(jsonPatch) ? jsonPatch : [jsonPatch];

  const filteredPatches = clonedPatches.filter(
    ({ path, op }) => !patch.find(item => path === item.path && op === item.op)
  );

  return [...filteredPatches, ...patch];
};

export default getPatch;
