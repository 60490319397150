/**
 * Get the extension of a filename
 *
 * @param {string} filename
 * @returns {string}
 */

const getFileExtension = filename => {
  return filename ? filename.split('.').pop() : '';
};

export default getFileExtension;
