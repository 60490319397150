const overrides = {
  MuiButton: {
    size: 'small',
  },
  MuiButtonGroup: {
    size: 'small',
  },
  MuiChip: {
    size: 'small',
  },
  MuiFab: {
    size: 'small',
  },
  MuiFilledInput: {
    margin: 'dense',
  },
  MuiFormControl: {
    margin: 'dense',
  },
  MuiFormHelperText: {
    margin: 'dense',
  },
  MuiIcon: {
    fonstSize: 'small',
  },
  MuiIconButton: {
    size: 'small',
  },
  MuiInput: {
    margin: 'dense',
  },
  MuiInputBase: {
    margin: 'dense',
  },
  MuiInputLabel: {
    margin: 'dense',
  },
  MuiList: {
    dense: true,
  },
  MuiListItem: {
    dense: true,
  },
  MuiMenuItem: {
    dense: true,
  },
  MuiOutlinedInput: {
    margin: 'dense',
  },
  MuiRating: {
    size: 'small',
  },
  MuiSvgIcon: {
    fontSize: 'small',
  },
  MuiSwitch: {
    size: 'small',
  },
  MuiTable: {
    size: 'small',
  },
  MuiTextField: {
    margin: 'dense',
  },
  MuiToggleButtonGroup: {
    size: 'small',
  },
  MuiToggleButton: {
    size: 'small',
  },
};

export default overrides;
