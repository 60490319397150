import moment from 'moment';
import { DATE_FORMAT, DATETIME_FORMAT } from '@consts';

/**
 * Get a typed value by specific GeoNAM attribute type
 * @param {Object} attributeType - GeoNAM attribute type
 * @param {string} value
 * @returns {*}
 */

const getTypedAttributeValue = (attributeType, value) => {
  switch (attributeType.dataType) {
    case 'STRING':
    case 'LINK':
    case 'EMAIL':
      return value;
    case 'INT':
    case 'FLOAT':
    case 'FRACTION':
    case 'CURRENCY':
      return value != null ? Number(value) : null;
    case 'BOOLEAN':
      if (value === 'true') {
        return true;
      } else if (value === 'false') {
        return false;
      }

      return null;
    case 'DATE':
      return value ? +moment(value, DATE_FORMAT) : null;
    case 'TIMESTAMP':
      return value ? +moment(value, DATETIME_FORMAT) : null;
    default:
      break;
  }
};

export default getTypedAttributeValue;
