import gql from 'graphql-tag';

const FragmentAttributeTypeAllAttributes = gql`
  fragment AttributeTypeAllAttributes2 on AttributeType {
    allowedValues {
      id
      className
      code
      name
      value
    }
    id
    className
    code
    dataType
    defaultValue
    grouping
    mandatory
    name
    options
    preselected
    readOnly
    searchable
    unit
  }
`;

export default FragmentAttributeTypeAllAttributes;
