/**
 * Resets the document with an updated entity. Draft status and json patches are removed.
 *
 * @param {RxDocument} doc
 * @param {object} updatedEntity
 * @returns
 */

const resetDoc = async (doc, updatedEntity) => {
  const resettedDoc = await doc.atomicUpdate(oldData => {
    oldData.entity = updatedEntity;
    oldData.draft = null;
    oldData.jsonPatch = null;
    return oldData;
  });

  return resettedDoc;
};

export default resetDoc;
