/**
 * A component to display a task.
 *
 * The task can be claimed/unclaimed.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import UserIcon from '@material-ui/icons/AccountCircle';

import { getEntityClass, isAllowed, isAllowedUPDATE, Trigger } from '@geomagic/geonam';
import { MutationClaimTask, MutationDelegate, MutationDisclaimTask } from '@geomagic/geonam-graphql';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_PROPS, PRIMARY_TRIGGER_PROPS } from '@consts';
import { CLASSNAME_USER } from '@graphql/consts';

import DelegateTaskDialog from './DelegateTaskDialog';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

import Action from './Action';

const useStyles = makeStyles(({ palette, spacing }) => ({
  task: {
    padding: 0,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: spacing(1.5),
    marginBottom: spacing(0.5),
    '& > *': {
      width: '100%',
    },
  },
  avatar: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
  },
  trigger: {
    minWidth: 'auto',
    marginTop: spacing(),
  },
}));

const DELEGATE_FORM_ID = 'DELEGATE_TASKS';

const Task = props => {
  const classes = useStyles();
  const {
    checkFailedMessage,
    closeableErrorText,
    data,
    entityClasses,
    task,
    isCloseable,
    isLoading,
    isMobile,
    isOnline,
    onCheck = () => true,
    onSyncEntity,
    onUpdateEntity,
    setLoading,
    user,
  } = props;

  const entity = data?.getPatchedEntity();

  const entityClass = getEntityClass(entityClasses, entity.className);

  const isAllowedUpdate = isAllowedUPDATE(entity);

  // Delegate permission only for assignments.
  const isAllowedDelegate =
    (isAllowed(entityClass, 'DELEGATE') || entity.className !== 'Assignment') && isAllowedUpdate;

  const { actions, assignee, id: taskId, name: taskName, processInstanceName, stage } = task;

  const isSelf = user?.loginName === assignee;
  const assigneeLabel = assignee || i18n.t('placeholder.noUserAssigned');
  const label = isSelf ? i18n.t('button.handOff') : i18n.t('button.takeOver');

  const enqueueLoadingSnackbar = useLoadingSnackbar();

  const { enqueueSnackbar } = useSnackbar();

  const [isDelagateDialogOpen, setDelegateDialogOpen] = useState(false);

  /**
   * GRAPHQL MUTATIONS
   */

  const [claimTask] = useMutation(MutationClaimTask);
  const [disclaimTask] = useMutation(MutationDisclaimTask);
  const [delegateTask] = useMutation(MutationDelegate);

  /**
   *  EVENT HANDLER
   */

  const syncEntity = async () => {
    await onSyncEntity(data);
  };

  const updateEntity = async () => {
    await onUpdateEntity(data);
  };

  const handleSubmitDelegateTask = async values => {
    setLoading(true);
    const { user, message } = values;
    const mutationConfig = {
      user: { id: user?.id, className: CLASSNAME_USER },
      message,
    };

    const execute = () =>
      syncEntity()
        .then(() => delegateTask({ variables: { ...mutationConfig, taskId } }))
        .then(updateEntity)
        .then(() => setDelegateDialogOpen(false));

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.delegateTask'),
      finishedText: i18n.t('process.notification.delegatedTask'),
      finishedVariant: 'success',
      func: execute,
    });
    setLoading(false);
  };

  const handleDelegateTaskOpen = async () => {
    const isChecked = await onCheck(data);

    if (!isChecked) {
      enqueueSnackbar(checkFailedMessage, {
        key: 'openDrafts',
        preventDuplicate: true,
        variant: 'info',
      });
    } else {
      setDelegateDialogOpen(true);
    }
  };

  const handleClaimTask = async () => {
    setLoading(true);

    const execute = () => claimTask({ variables: { taskId } }).then(updateEntity);

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.claimTask'),
      finishedText: i18n.t('process.notification.taskClaimed'),
      finishedVariant: 'success',
      func: execute,
    });
    setLoading(false);
  };

  const handleDisclaimTask = async () => {
    setLoading(true);

    const execute = () =>
      syncEntity()
        .then(() => disclaimTask({ variables: { taskId } }))
        .then(updateEntity);

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('process.notification.disclaimTask'),
      finishedText: i18n.t('process.notification.taskDisclaimed'),
      finishedVariant: 'success',
      func: execute,
    });
    setLoading(false);
  };

  return (
    <List className={classes.task}>
      <ListItem disableGutters>
        <ListItemText
          primary={
            <Typography variant="body2" noWrap>
              {taskName}
            </Typography>
          }
          secondary={processInstanceName + (stage ? ` (${stage})` : '')}
        />
      </ListItem>
      <ListItem disableGutters divider={actions.length > 0}>
        {assignee && (
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <UserIcon />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            <Typography variant="body2" color="textSecondary" noWrap>
              {assigneeLabel}
            </Typography>
          }
        />
        <Trigger
          className={classes.trigger}
          onClick={isSelf ? handleDisclaimTask : handleClaimTask}
          disabled={!isOnline || isLoading}
          {...DEFAULT_TRIGGER_PROPS}
        >
          {label}
        </Trigger>
      </ListItem>
      <div className={classes.actions}>
        {actions.map(action => (
          <Action
            key={action.id}
            action={action}
            checkFailedMessage={checkFailedMessage}
            className={classes.trigger}
            closeableErrorText={closeableErrorText}
            data={data}
            isCloseable={isCloseable}
            isLoading={isLoading}
            isMobile={isMobile}
            isOnline={isOnline}
            isSelf={isSelf}
            onCheck={onCheck}
            setLoading={setLoading}
            syncEntity={syncEntity}
            task={task}
            updateEntity={updateEntity}
          />
        ))}
        {isAllowedDelegate && (
          <>
            <Trigger
              className={classes.trigger}
              disabled={!isOnline || isLoading}
              fullWidth
              onClick={handleDelegateTaskOpen}
              {...PRIMARY_TRIGGER_PROPS}
            >
              {i18n.t('process.label.delgateTask')}
            </Trigger>
            <DelegateTaskDialog
              formId={DELEGATE_FORM_ID}
              isLoading={isLoading}
              isMobile={isMobile}
              open={isDelagateDialogOpen}
              onClose={() => setDelegateDialogOpen(false)}
              onSubmit={handleSubmitDelegateTask}
              taskId={taskId}
              taskName={taskName}
              user={user}
            />
          </>
        )}
      </div>
    </List>
  );
};

Task.propTypes = {
  checkFailedMessage: PropTypes.string,
  className: PropTypes.string,
  closeableErrorText: PropTypes.string,
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array,
  isCloseable: PropTypes.bool,
  isOnline: PropTypes.bool,
  isMobile: PropTypes.bool,
  onCheck: PropTypes.func,
  onSyncEntity: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  task: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        description: PropTypes.string,
        disabled: PropTypes.bool,
        commands: PropTypes.arrayOf(
          PropTypes.shape({
            confirmation: PropTypes.bool,
            command: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    assignee: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    priority: PropTypes.string,
    stage: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
};

export default Task;
