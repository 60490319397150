import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const useStyles = makeStyles(({ palette, spacing, typography }) => {
  return {
    listItem: {
      borderBottom: `1px solid ${palette.divider}`,
      backgroundColor: palette.background.paper,
      minHeight: 70,
      paddingLeft: spacing(),
      '&$firstItem': {
        borderTop: `1px solid ${palette.divider}`,
      },
    },
    listItemAvatar: {
      minWidth: 0,
    },
    listItemSecondaryAction: {
      right: spacing(),
    },
  };
});

const ListItemComponent = props => {
  const {
    ActionsComponent,
    activeId,
    additionalProps,
    AvatarComponent,
    item,
    itemId,
    listItemRefs,
    onClick,
    TextComponent,
  } = props;

  const classes = useStyles(props);

  return (
    <ListItem
      ref={el => (listItemRefs.current[itemId] = el)}
      className={classes.listItem}
      selected={itemId === activeId}
      {...(onClick && { button: true, onClick: () => onClick(item) })}
    >
      {AvatarComponent && (
        <ListItemAvatar className={classes.listItemAvatar}>
          <AvatarComponent data={item} {...additionalProps} />
        </ListItemAvatar>
      )}
      {TextComponent && <TextComponent data={item} {...additionalProps} />}
      {ActionsComponent && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <ActionsComponent data={item} {...additionalProps} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ListItemComponent.propTypes = {
  ActionsComponent: PropTypes.object,
  activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  additionalProps: PropTypes.object,
  AvatarComponent: PropTypes.object,
  item: PropTypes.object,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listItemRefs: PropTypes.object,
  onClick: PropTypes.func,
  TextComponent: PropTypes.object,
};

export default memo(ListItemComponent);
