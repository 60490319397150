import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import sanitizePath from '@utils/sanitizePath';

const useStyles = makeStyles(({ mixins, palette, spacing }) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderTop: `1px solid ${palette.divider}`,
  },
}));

const MobileNavigation = props => {
  const { activeId, basePath, className, items, onChange } = props;
  const classes = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleChange = id => () => {
    onChange(id);
  };

  return (
    <BottomNavigation className={classNames(classes.root, className)} showLabels value={activeId}>
      {items.map(({ id, name, path, Icon: IconComponent }) => (
        <BottomNavigationAction
          key={id}
          icon={IconComponent && <IconComponent />}
          label={name}
          onClick={handleChange(id)}
          value={id}
          {...(basePath && path && { component: Link, to: sanitizePath(`${basePath}${path}`) })}
          selected={true}
        />
      ))}
    </BottomNavigation>
  );
};

MobileNavigation.propTypes = {
  activeId: PropTypes.string.isRequired,
  basePath: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      id: PropTypes.string.isRequired,
      path: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MobileNavigation;
