import compact from 'lodash/compact';

/**
 * Create groups by GeoNAM grouping array
 * @param {Array} grouping - GeoNAM grouping array
 * @returns {Array.<Object>}
 */

const getLayerGroups = (grouping, isVisible) => {
  let layerGroups;
  if (grouping) {
    layerGroups = compact(grouping).reduce((prev, next) => {
      const groupingVar = [
        ...prev,
        {
          id: next.id,
          _id: next.id,
          filter: null,
          layerPackage: 'default',
          layerValues: { opacity: 1 },
          isVisible,
          isBackground: false,
          name: next.id,
          groupName: next.name,
          selectAll: next.selectAll,
          type: 'Group',
          values: null,
          ...(prev.length > 0 && { parent: prev[prev.length - 1]._id }),
        },
      ];
      return groupingVar;
    }, []);
  }

  return layerGroups;
};

export default getLayerGroups;
