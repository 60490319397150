import { getAttributeTypesByClassAndType, getReference } from '@geomagic/geonam';
import { MutationCreateEntity } from '@geomagic/geonam-graphql';

const transformer = ({ attributeType, value }) => ({
  attributeTypeId: attributeType.id,
  value,
});

const createEntity = async ({ client, entityClasses, patchedEntity }) => {
  const className = patchedEntity?.className;
  const entityTypeId = patchedEntity?.entityType?.id;
  const attributeTypes = getAttributeTypesByClassAndType(entityClasses, patchedEntity?.className, entityTypeId);
  const _attributeValues = patchedEntity?.attributeValues;

  const attributeValues = _attributeValues.map(transformer).filter(({ attributeTypeId }) => {
    const { readOnly } = attributeTypes.find(({ id }) => id === attributeTypeId);
    return !readOnly;
  });

  const createdEntity = await client.mutate({
    mutation: MutationCreateEntity,
    variables: { entity: { attributeValues, className, entityTypeId, accessPermissions: [], relations: [] } },
  });

  return createdEntity?.data?.createEntity && getReference(createdEntity?.data?.createEntity);
};

export default createEntity;
