import { Modify, Snap } from 'ol/interaction';

/**
 * Add modify and snap interaction to OpenLayers map
 * @param {Object} params
 * @param {Object} params.map - OpenLayers map
 * @param {Array.<Object>} params.features - OpenLayers feature collection
 * @param {function} params.onModified - Function which is called after modifying
 * @returns {Array.<Object>}
 */

const enableModifyInteraction = ({ map, features, onModified }) => {
  const snap = new Snap({ features });
  const modify = new Modify({ features });

  modify.on('modifyend', function(event) {
    const { features: featureCollection } = event;
    const modifiedFeature = featureCollection.getArray()[0];

    onModified(event, modifiedFeature);
  });

  map.addInteraction(snap);
  map.addInteraction(modify);

  return [snap, modify];
};

export default enableModifyInteraction;
