import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import DynamicAvatar from '@components/DynamicAvatar';
import getDispatchesWithDefectStatus from './utils/getDispatchesWithDefectStatus';
import getProgress from './utils/getProgress';

const TOTAL_VALUE = 100;
const DEFAULT_SIZE = 40;
const VARIANT_CIRCULAR_THICKNESS = 4;
const VARIANT_ICON_SIZE = 16;
const VARIANT_ICON_THICKNESS = 2;
const VARIANT_LINEAR_SIZE = 4;

const useStyles = makeStyles(({ palette, shape, spacing, typography }) => {
  const defaultBackground = palette.grey[palette.type === 'light' ? 300 : 500];

  return {
    root: {
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      height: '100%',
      '&$circular': {
        minHeight: DEFAULT_SIZE,
        marginRight: spacing(2),
        textAlign: 'center',
      },
      '&$icon': {
        minHeight: DEFAULT_SIZE,
        marginRight: spacing(2),
      },
    },
    component: {
      '&$circular': {
        position: 'absolute',
      },
      '&$icon': {
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: VARIANT_ICON_THICKNESS,
        minHeight: VARIANT_ICON_SIZE,
        minWidth: VARIANT_ICON_SIZE,
        '&$success': {
          background: palette.success.main,
          borderColor: palette.success.dark,
        },
        '&$warning': {
          background: palette.warning.main,
          borderColor: palette.warning.dark,
        },
      },
      '&$linear': {
        height: VARIANT_LINEAR_SIZE,
        width: '100%',
      },
    },
    avatar: {
      background: palette.background.paper,
      border: `1px solid ${palette.background.paper}`,
      borderRadius: '50%',
      color: palette.primary.main,
      height: 20,
      position: 'absolute',
      width: 20,
      '&$circular': {
        bottom: -4,
        right: -4,
      },
      '&$icon': {
        bottom: 0,
        right: -12,
      },
    },
    bar: {
      background: defaultBackground,
      '&$success': {
        background: palette.success.main,
      },
      '&$warning': {
        background: palette.warning.main,
      },
    },
    barPrimary: {
      background: defaultBackground,
    },
    circle: {
      color: defaultBackground,
      '&$success': {
        color: palette.success.main,
      },
      '&$warning': {
        color: palette.warning.main,
      },
    },
    label: {
      ...typography.caption,
      color: 'inherit',
      fontWeight: 600,
      letterSpacing: 0,
      marginTop: 1,
      width: DEFAULT_SIZE,
      '&$success': {
        color: palette.getContrastText(palette.success.dark),
      },
      '&$warning': {
        color: palette.getContrastText(palette.warning.main),
      },
    },
    success: {},
    warning: {},
    circular: {},
    icon: {},
    linear: {},
  };
});

const Progress = props => {
  const classes = useStyles(props);
  const { className, forms = [], variant } = props;

  const hasDispatches = variant !== 'linear' && getDispatchesWithDefectStatus(forms).length > 0;
  const { progress, total } = getProgress(forms);
  const [normalizedValue, setNormalizedValue] = useState(0);
  const isProgress = variant === 'progress';

  useEffect(() => {
    let timer;
    const value = total === 0 ? 0 : ((progress - 0) * TOTAL_VALUE) / (total - 0);

    if (isProgress) {
      timer = setTimeout(() => {
        setNormalizedValue(prevProgress => prevProgress + value);
      }, 500);
    } else {
      setNormalizedValue(value);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isProgress, progress, total]);

  if (total < 1) return null;

  const label = total;

  const stateClasses = {
    [classes.success]: normalizedValue === TOTAL_VALUE,
    [classes.warning]: normalizedValue >= 0 && normalizedValue < TOTAL_VALUE,
  };

  let Component = null;

  if (variant === 'icon') {
    Component = <div className={classNames(classes.component, classes[variant], stateClasses)} />;
  }

  if (variant === 'circular') {
    const circularProps = {
      size: DEFAULT_SIZE,
      thickness: VARIANT_CIRCULAR_THICKNESS,
      variant: 'determinate',
    };

    Component = (
      <Fragment>
        <CircularProgress
          classes={{ root: classNames(classes.component, classes[variant]), circle: classes.circle }}
          value={TOTAL_VALUE}
          {...circularProps}
        />
        <CircularProgress
          classes={{
            root: classNames(classes.component, classes[variant]),
            circle: classNames(classes.circle, stateClasses),
          }}
          value={normalizedValue}
          {...circularProps}
        />
        <Typography className={classes.label}>{label}</Typography>
      </Fragment>
    );
  }

  if (variant === 'linear') {
    Component = (
      <LinearProgress
        classes={{
          root: classNames(classes.component, classes[variant]),
          bar: classNames(classes.bar, stateClasses),
          colorPrimary: classes.barPrimary,
        }}
        value={normalizedValue}
        variant="determinate"
      />
    );
  }

  return (
    <div className={classNames(classes.root, className, classes[variant])}>
      {Component}
      {hasDispatches && (
        <DynamicAvatar
          className={classNames(classes.avatar, classes[variant])}
          imp={import(/* webpackMode: "eager" */ `@geomagic/nam-react-core/icons/Dispatch`)}
        />
      )}
    </div>
  );
};

Progress.propTypes = {
  className: PropTypes.string,
  forms: PropTypes.array,
  variant: PropTypes.oneOf(['icon', 'circular', 'linear']).isRequired,
};

export default Progress;
