import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ spacing }) => ({
  root: {},
  title: {},
  value: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ReadOnlyField = props => {
  const classes = useStyles(props);
  const { className, formControlProps, title, titleProps, value, valueProps } = props;

  return (
    <FormControl className={classNames(classes.root, className)} fullWidth margin="none" {...formControlProps}>
      {title && (
        <Typography className={classes.title} variant="body2" color="textSecondary" noWrap {...titleProps}>
          {title}
        </Typography>
      )}
      {value && (
        <Typography className={classes.value} variant="body2" noWrap {...valueProps}>
          {value}
        </Typography>
      )}
    </FormControl>
  );
};

ReadOnlyField.propTypes = {
  className: PropTypes.string,
  formControlProps: PropTypes.object,
  title: PropTypes.node,
  titleProps: PropTypes.object,
  value: PropTypes.node,
  valueProps: PropTypes.object,
};

export default ReadOnlyField;
