import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pointer from 'json-pointer';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRxDB } from 'rxdb-hooks';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import GeoJSON from 'ol/format/GeoJSON';

import { i18n } from '@geomagic/i18n';
import {
  getAttributeTypesByClassAndType,
  getEntityClass,
  getEntityType,
  getReference,
  Trigger,
} from '@geomagic/geonam';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';

import { CREATE_DISPATCHES_KEY } from '@consts';
import getPatch from '@database/getPatch';
import useDrawInteraction from '@components/Map/utils/useDrawInteraction';
import useFeatures from '@components/Map/utils/useFeatures';
import getEntityTemplate from '@database/getEntityTemplate';
import getGeometryTypesByStyleId from '@components/Map/utils/getGeometryTypesByStyleId';
import getDocTemplate from '@database/getDocTemplate';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import { RELEVANT_DISPATCH_PATH } from '@graphql/consts';
import useBackButton from '@utils/useBackButton';

import { getDefaultFeatureStyle, getFeatures } from '@components/Dispatch';
import DCMImageUpload from './DCMImageUpload';
import DCMAttributes from './DCMAttributes';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {},
  fab: {
    position: 'absolute',
    bottom: spacing(2),
    right: spacing(2),
    zIndex: 2,
  },
  stepper: {
    background: palette.background.default,
    flex: 1,
  },
}));

const DISPATCH_CLASSNAME = 'Dispatch';
const DISPATCH_COLLECTION_KEY = 'dispatches';
const ASSIGNMENT_COLLECTION_KEY = 'assignments';

const getClosedElementPatch = ({ entity, path, updateId, update }) => {
  const items = pointer.get(entity, path);
  const updatedItems = items.map(item => (item.id === updateId ? { ...item, ...update } : item));

  return {
    op: 'replace',
    path,
    value: updatedItems,
  };
};

const DispatchCreationMode = props => {
  const {
    assignmentId,
    dispatchCreationConfig = {},
    docRelations,
    entityClasses,
    formElementBlockId,
    isMobile,
    mapProps,
    user,
  } = props;
  const classes = useStyles(props);
  const [currentDispatch, setCurrentDispatch] = useState(null);
  const [features, setFeatures] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [imagesAdded, setImagesAdded] = useState(false);
  const [, setCreateDispatchesMode] = useStickySessionState(CREATE_DISPATCHES_KEY);

  const { addBackAction, resetBackActions } = useBackButton();

  const isDrawing = activeStep === 0 && !!currentDispatch;
  const isImageUpload = activeStep === 1 && !!currentDispatch;
  const isAttributesFill = activeStep === 2 && !!currentDispatch;

  const { primaryColor, maxExtentZoomLevel, selectColor, srid, mapRef } = mapProps;

  const entityClassDispatch = getEntityClass(entityClasses, DISPATCH_CLASSNAME);

  const userId = user?.id;

  const fileInputRef = useRef();
  const database = useRxDB();

  const {
    dispatchAttribTypes: dispatchAttribTypesReferences = [],
    dispatchTypeId,
    documentTypeId,
    geometryStyleId,
  } = dispatchCreationConfig;

  const isWithoutAttributesFill = dispatchAttribTypesReferences.length === 0;

  const selectedGeometryStyle = useMemo(() => {
    const entityType = getEntityType(entityClasses, DISPATCH_CLASSNAME, dispatchTypeId);
    const { stylesByShapeType = [] } = getGeometryTypesByStyleId(entityType, entityClasses);
    return stylesByShapeType.find(geometryStyle => geometryStyle.id === geometryStyleId);
  }, [dispatchTypeId, entityClasses, geometryStyleId]);

  const allAttributeTypes = useMemo(
    () => getAttributeTypesByClassAndType(entityClasses, DISPATCH_CLASSNAME, dispatchTypeId),
    [entityClasses, dispatchTypeId]
  );

  const dispatchAttribTypes = useMemo(() => {
    return dispatchAttribTypesReferences.map(attribTypeReference =>
      allAttributeTypes.find(attribType => attribType.id === attribTypeReference.id)
    );
  }, [allAttributeTypes, dispatchAttribTypesReferences]);

  const theme = useTheme();
  const isSmallMobile = useMediaQuery(mediaQueryTheme => mediaQueryTheme.breakpoints.down('xs'));

  const updateFeatures = useCallback(async () => {
    console.log('start update features');
    const assignmentCollection = await database[ASSIGNMENT_COLLECTION_KEY];
    const selector = { [ENTITY_SELECTOR_KEY]: assignmentId };
    const assignmentDoc = await assignmentCollection.findOne({ selector }).exec();
    const { relevantDispatches } = assignmentDoc.getPatchedEntity();
    const collection = await database[DISPATCH_COLLECTION_KEY];
    console.log('update features: got dispatch collection');
    const relevantDispatchesDocs = await collection
      .find({
        selector: { [ENTITY_SELECTOR_KEY]: { $in: relevantDispatches.map(({ id }) => id) }, userId },
        sort: [{ modifiedOn: 'desc' }],
      })
      .exec();

    console.log('update features: got relevant dispatches');
    const newFeatures = getFeatures(relevantDispatchesDocs, entityClasses);
    setFeatures(newFeatures);
  }, [assignmentId, database, entityClasses, userId]);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    addBackAction(() => setCreateDispatchesMode(null));
    updateFeatures();
    // Only once on render add
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  EVENT HANDLER
   */

  const getAssignmentDoc = async () => {
    const assignmentCollection = await database[ASSIGNMENT_COLLECTION_KEY];
    const selector = { [ENTITY_SELECTOR_KEY]: assignmentId };
    const assignmentDoc = await assignmentCollection.findOne({ selector }).exec();
    return assignmentDoc;
  };

  const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

  const handleUpdateDraft = async newPatch => {
    const jsonPatch = currentDispatch?.jsonPatch;

    await currentDispatch.atomicUpdate(oldData => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });
  };

  const resetCurrentDispatch = () => {
    setCurrentDispatch(null);
    setActiveStep(0);
    setImagesAdded(false);
    resetBackActions();
    addBackAction(() => setCreateDispatchesMode(null));
  };

  const handleBackToGeometry = async () => {
    await handleResetGeometry();
    setActiveStep(0);
  };

  const handleBackToImages = () => {
    setActiveStep(1);
  };

  const handleCancel = async dispatchDoc => {
    await dispatchDoc.remove();
    const assignmentDoc = await getAssignmentDoc();
    const { relevantDispatches } = assignmentDoc.getPatchedEntity();

    const newRelevantDispatches = relevantDispatches.filter(
      dispatch => dispatch.id !== dispatchDoc.getPatchedEntity().id
    );

    const newPatch = {
      op: 'replace',
      path: RELEVANT_DISPATCH_PATH,
      value: newRelevantDispatches,
    };

    const { jsonPatch } = assignmentDoc;

    await assignmentDoc.atomicUpdate(oldData => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });

    resetCurrentDispatch();
  };

  const handleConfirmImages = async () => {
    if (isWithoutAttributesFill) {
      await handleCloseDraft();
      resetCurrentDispatch();
    } else {
      setActiveStep(2);
      addBackAction(handleBackToImages);
    }
  };

  const handleDrawed = async (event, drawedFeature) => {
    const newGeoJSONFeature = JSON.parse(new GeoJSON().writeFeature(drawedFeature));
    const { properties, ...rest } = newGeoJSONFeature;
    const newFeatures = [
      {
        ...rest,
        geometryStyleId: selectedGeometryStyle?.id,
        srid,
      },
    ];
    const newPatch = {
      op: 'replace',
      path: '/featureCollections/0/features',
      value: newFeatures,
    };
    setActiveStep(1);
    handleUpdateDraft(newPatch).then(data => {
      updateFeatures();
    });
    if (!imagesAdded) {
      fileInputRef.current.click();
      setImagesAdded(true);
    }
    addBackAction(handleBackToGeometry);
  };

  const handleNewDispatch = async () => {
    const dispatchCollection = await database[DISPATCH_COLLECTION_KEY];
    const entity = getEntityTemplate(entityClassDispatch, dispatchTypeId);
    const dispatchTemplate = getDocTemplate({ entity, isDraft: true, mapProps, relations: docRelations, userId });

    const dispatchDoc = await dispatchCollection.insert(dispatchTemplate);

    if (dispatchDoc) {
      const dispatchReference = getReference(dispatchDoc.getPatchedEntity());

      const assignmentDoc = await getAssignmentDoc();
      const { relevantDispatches } = assignmentDoc.getPatchedEntity();

      const newRelevantDispatches = [
        ...relevantDispatches,
        { ...dispatchReference, closed: false, blockId: formElementBlockId },
      ];

      const newPatch = {
        op: 'replace',
        path: RELEVANT_DISPATCH_PATH,
        value: newRelevantDispatches,
      };

      const { jsonPatch } = assignmentDoc;

      await assignmentDoc.atomicUpdate(oldData => {
        oldData.jsonPatch = getPatch(jsonPatch, newPatch);
        return oldData;
      });

      setCurrentDispatch(dispatchDoc);
      addBackAction(() => handleCancel(dispatchDoc));
    }
  };

  const handleCloseDraft = async () => {
    const { uuid } = currentDispatch;
    const update = { closed: true };

    const assignmentDoc = await getAssignmentDoc();
    const jsonPatch = assignmentDoc?.jsonPatch;
    const patchedEntity = assignmentDoc.getPatchedEntity();

    const newPatch = getClosedElementPatch({
      entity: patchedEntity,
      path: '/relevantDispatches',
      updateId: uuid,
      update,
    });

    await assignmentDoc.atomicUpdate(oldData => {
      oldData.jsonPatch = getPatch(jsonPatch, newPatch);
      return oldData;
    });

    await currentDispatch.atomicUpdate(oldData => {
      oldData.draft = { ...oldData.draft, closed: true };
      return oldData;
    });
  };

  const handleFillAttributes = async values => {
    const newPatch = {
      op: 'replace',
      path: `/attributeValues`,
      value: values,
    };

    await handleUpdateDraft(newPatch);

    await handleCloseDraft();
    resetCurrentDispatch();
  };

  const handleResetGeometry = async () => {
    const newPatch = {
      op: 'replace',
      path: '/featureCollections/0/features',
      value: [],
    };
    handleUpdateDraft(newPatch).then(data => {
      updateFeatures();
    });
  };

  /**
   *  MAP
   */

  const drawerHandler = { onDrawed: handleDrawed };

  useFeatures({
    features,
    isSelectable: false,
    maxExtentZoomLevel,
    mapRef,
    selectColor,
    style: getFeatureStyle,
    withZoom: false,
  });

  useDrawInteraction({ mapRef, geometryStyle: isDrawing ? selectedGeometryStyle : null }, drawerHandler);

  return (
    <>
      {currentDispatch ? (
        <>
          <Stepper className={classNames(classes.stepper)} activeStep={activeStep} alternativeLabel={isSmallMobile}>
            <Step>
              <StepLabel>{i18n.t('dispatch.label.addLocation')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{i18n.t('dispatch.label.addPhotos')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{i18n.t('dispatch.label.fillAttributes')}</StepLabel>
            </Step>
          </Stepper>
          <DCMImageUpload
            doc={currentDispatch}
            documentTypeId={documentTypeId}
            fileInputRef={fileInputRef}
            handleUpdateDraft={handleUpdateDraft}
            isImageUpload={isImageUpload}
            isMobile={isMobile}
            isSmallMobile={isSmallMobile}
            onBack={handleBackToGeometry}
            onConfirm={handleConfirmImages}
          />
          <DCMAttributes
            allAttributeTypes={allAttributeTypes}
            doc={currentDispatch}
            dispatchAttribTypes={dispatchAttribTypes}
            isAttributesFill={isAttributesFill}
            isSmallMobile={isSmallMobile}
            onBack={handleBackToImages}
            onConfirm={handleFillAttributes}
          />
        </>
      ) : null}

      <Trigger
        className={classes.fab}
        color="primary"
        onClick={!!currentDispatch ? () => handleCancel(currentDispatch) : handleNewDispatch}
        icon={!!currentDispatch ? <CancelIcon /> : <AddIcon />}
        variant="fab"
      />
    </>
  );
};

DispatchCreationMode.propTypes = {
  assignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatchCreationConfig: PropTypes.object,
  docRelations: PropTypes.array,
  entityClasses: PropTypes.array,
  formElementBlockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object,
  user: PropTypes.object,
};

export default DispatchCreationMode;
