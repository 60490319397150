import { Fill, Stroke, Style } from 'ol/style';
import hexToRGB from './hexToRGB';

/**
 * Get an object with styles for geometry types
 * @param {Object} params
 * @returns {Object}
 */

const getFeatureStyle = (params = {}) => {
  const { backgroundColor, borderWidth = 2, zIndex = 1 } = params;

  return [
    new Style({
      fill: new Fill({ color: hexToRGB(backgroundColor, 0.5) }),
      zIndex,
    }),
    new Style({
      stroke: new Stroke({ color: backgroundColor, width: borderWidth }),
      zIndex,
    }),
  ];
};

export default getFeatureStyle;
