import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ breakpoints, palette, spacing, transitions }) => ({
  root: {
    pointerEvents: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const TriggerDrawInteractions = props => {
  const { icon, onChange, onClick, stylesByShapeType = [], triggerProps } = props;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  const handleClick = event => {
    const selectedShapeType = stylesByShapeType[selectedIndex];
    onClick && onClick(selectedShapeType);
  };

  const handleClickMenuItem = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    onChange && onChange(event, index);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup color="default" ref={anchorRef} variant="contained" {...triggerProps}>
        <Button classes={{ label: classes.label }} onClick={handleClick} startIcon={icon}>
          <Typography variant="button" noWrap>
            {stylesByShapeType[selectedIndex]?.name}
          </Typography>
        </Button>
        {stylesByShapeType.length > 1 && (
          <Button onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper anchorEl={anchorRef.current} disablePortal open={open} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {stylesByShapeType.map((item, index) => {
                    const { id, name } = item;
                    return (
                      <MenuItem
                        key={id}
                        selected={index === selectedIndex}
                        onClick={event => handleClickMenuItem(event, index)}
                      >
                        {name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

TriggerDrawInteractions.propTypes = {
  icon: PropTypes.node,
  stylesByShapeType: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.node.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

export default TriggerDrawInteractions;
