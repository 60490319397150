import pointer from 'json-pointer';
import { MutationAddGeometries, MutationRemoveGeometry, MutationUpdateGeometry } from '@geomagic/geonam-graphql';

import getDiffs from '../getDiffs';

const UPDATE_KEY = 'featureId';

const transformer = ({ id, geometry, geometryStyleId, srid }) => ({
  [UPDATE_KEY]: id,
  geometry: { ...geometry, srid },
  geometryStyleId,
});

const updateGeometries = async ({ client, entityReference, entity, patchedEntity, path }) => {
  const features = pointer.get(entity, path);
  const patchedFeatures = pointer.get(patchedEntity, path);

  const transformedFeatures = features.map(transformer);
  const transformedPatchedFeatures = patchedFeatures.map(transformer);

  const { added, removed, updated } = getDiffs(transformedFeatures, transformedPatchedFeatures, UPDATE_KEY);

  if (added.length > 0) {
    await client.mutate({
      mutation: MutationAddGeometries,
      variables: { entityReference, geometries: added.map(({ featureId, ...feature }) => feature) },
    });
  }

  if (updated.length > 0) {
    for (let i = 0; i < updated.length; i++) {
      const { featureId, geometry } = updated[i];

      await client.mutate({
        mutation: MutationUpdateGeometry,
        variables: { entityReference, featureId, geometry },
      });
    }
  }

  if (removed.length > 0) {
    for (let i = 0; i < removed.length; i++) {
      const { featureId } = removed[i];

      await client.mutate({
        mutation: MutationRemoveGeometry,
        variables: { entityReference, featureId },
      });
    }
  }
};

export default updateGeometries;
